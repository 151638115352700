/**
 * Name: Header component
 * Description: Site wide header
 * Author: omeiza@bigcabal.com
 */

import * as React from "react";
import { Link } from "react-router-dom";
import Menu from "../Menu/Menu";
import { useAuth } from "../Auth/AuthProvider";
import UploadPopup from "../Meme/UploadPopup";

// Styles
import "./Header.scss";
import axios from "axios";
import {getFromLocalStorage} from "../../util/helpers";

const Header = () => {
	const [showMenu, setShowMenu] = React.useState(false);
	const [showUploadPopup, setUploadPopup] = React.useState(false);
	const [verificationRequested, setVerificationRequested] = React.useState(false);
	const auth = useAuth();
	const [isAuthenticated, setIsAuthenticated] = React.useState(auth.isLoggedIn() && auth.user);

	React.useEffect(() => {
		const lclIsAuthenticated = auth.isLoggedIn() && auth.user;
		if (lclIsAuthenticated !== isAuthenticated) setIsAuthenticated(lclIsAuthenticated);
	}, [ auth ]);

	function togglePopup() {
		setShowMenu(!showMenu);

		if (showMenu) {
			document.body.style.overflow = "scroll";
		}
	}

	function toggleUploadPopup() {
		setUploadPopup(!showUploadPopup);

		if (showUploadPopup) {
			document.body.style.overflow = "scroll";
		}
	}

	function resendVerification(event) {
		event.preventDefault();
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/verify/generate`, {
			headers: {
				'x-api-key': getFromLocalStorage("key"),
				'Content-Type': 'application/json'
			}
		})
			.then(result => {
				if (result) setVerificationRequested(!verificationRequested);
			})
	}

	return (
		<>
			{
				isAuthenticated && !auth.user?.isVerified ?
					verificationRequested ?
						<section className="verify-email">
							<div className="container">
								Verification email successfully sent. Check your email.
							</div>
						</section>
						:
						<section className="verify-email">
							<div className="container">
								Verify your email to get the most out of Z! Memes. Didn't receive an email? <a href="/" onClick={e => resendVerification(e)}>Resend confirmation</a>
							</div>
						</section>
					:
					null
			}
			<header className="header">
				<div className="container">
					<div className="grid grid-header">
						<div className="logo">
							<Link to="/"><img width={140} src="/images/zikoko-memes-logo.svg" alt="Zikoko Memes" /></Link>
						</div>
						<div className="header-status">
							{/* { button } */}
							{ (auth.isLoggedIn() && auth.user) ?		
							(<>
								{
									auth.user.isActive ?
										<div onClick={toggleUploadPopup.bind(this)} className="btn btn-colored meme-create-button">Create a Meme</div>
										:
										<Link to="/blocked-user" className="btn btn-colored">Create a Meme</Link>
								}

								<Link to={`/profile/${auth.user.username}`} className="icon icon-profile"><img width={28} src="/images/profile-circle.svg" alt="Profile Icon"/></Link>
							</>) : (
							<>
								<Link to="/login" className="btn btn-colored">Create a Meme</Link>
								<Link to="/login" className="icon icon-profile"><img width={28} src="/images/profile-circle.svg" alt="Profile Icon"/></Link>
							</>
							) }

							<div className="icon icon-hamburger">
								<img onClick={togglePopup.bind(this)} width={28} src="/images/menu.svg" alt="Profile Icon"/>
							</div>
						</div>
					</div>
				</div>
			</header>
			{ showMenu ? <Menu closePopup={togglePopup.bind(this)} /> : null }
			{ showUploadPopup ? <UploadPopup closeUploadPopup={toggleUploadPopup.bind(this)} /> : null }
		</>
	)
}

export default Header;