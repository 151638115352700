import * as React from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Search from "../components/Search/Search";
import { ListSection, MediaTabs } from "../components/ListSection";
import AdContext from "../components/Ad/AdContext";
import Ad from "../components/Ad";
import {Helmet} from "react-helmet";

const SearchRoute = () => {
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const query = searchParams.get('q');
	const tag = searchParams.get('tag');
	const source = searchParams.get('source');
	const type = searchParams.get('type');

	let title = query ? `Search result for "${query}"` : 'All memes';
	if (tag) title = tag ? `Search result for "${tag}"` : 'All memes';

	const tabs = MediaTabs;
	if (tabs[type]) {
		tabs['_default'] = type;
	}

	return (
		<>
			<Helmet>
				<title>{`${title} | Zikoko! Memes`}</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content={`${title} | Zikoko! Memes`}/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<Layout>
				<AdContext>
					<Search />
					<Ad id="AFTER_SEARCH" type="leaderboard" />
					<main>
						<ListSection pageClassName="search-memes" title={title}
						             endPoint='/memes' args={{q: query, tag: tag, source: source}} columns="4" mobileColumns="2" className="dynamic" masonry="true" autoload="true"
						             tabs={MediaTabs}
						/>
					</main>
					<Ad id="BEFORE_FOOTER" type="leaderboard" />
				</AdContext>
			</Layout>
		</>
	);
}

export default SearchRoute;
