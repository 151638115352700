import * as React from "react";
import Layout from "../components/Layout/Layout";
import Search from "../components/Search/Search";
import Leaderboard from "../components/Leaderboard/Leaderboard";
import AdContext from "../components/Ad/AdContext";
import Ad from "../components/Ad";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const LeaderboardRoute = () => {
	const location = useLocation();

	return (
		<>
			<Helmet>
				<title>Leaderboard | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="Leaderboard | Zikoko! Memes"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<Layout>
				<AdContext>
					<Search />
					<Ad id="BEFORE_LEADERBOARD" type="leaderboard" />
					<main>
						<Leaderboard />
					</main>
				</AdContext>
			</Layout>
		</>

	);
}

export default LeaderboardRoute;
