import Layout from "../components/Layout/Layout";
import ProfileEdit from "../components/User/ProfileEdit";

const ProfileRoute = () => {

	return (
		<Layout>
			<>
				<main>
					<ProfileEdit />
				</main>
			</>
		</Layout>
	);
}

export default ProfileRoute;
