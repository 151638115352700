import { Routes, Route } from "react-router-dom";
import { useAuth } from "./components/Auth/AuthProvider";

// Pages
import CommunityGuideline from "./routes/pages/CommunityGuideline";
import Copyright from "./routes/pages/Copyright";
import ManageCookies from "./routes/pages/ManageCookies";
import PrivacyPolicy from "./routes/pages/PrivacyPolicy";
import TermsOfService from "./routes/pages/TermsOfService";
import NotFound from "./routes/pages/NotFound";
import Login from "./routes/auth/Login";
import Signup from "./routes/auth/Signup";
import ForgotPassword from "./routes/auth/ForgotPassword";
import TrendingMemes from "./routes/TrendingMemes";
import TopMemes from "./routes/TopMemes";
import Topics from "./routes/Topics";
import Topic from "./routes/Topic";
import Home from "./routes/Home";
import MemeRoute from "./routes/MemeRoute";
import SearchRoute from "./routes/SearchRoute";
import ResetPassword from "./routes/auth/ResetPassword";
import ProfileRoute from "./routes/Profile";
import ProfileEdit from "./routes/ProfileEdit";
import UploadMemeRoute from "./routes/UploadMemeRoute";
import About from "./routes/pages/About";
import LeaderboardRoute from "./routes/Leaderboard";
import BlockedUser from "./routes/pages/BlockedUser";
import RemixMemeRoute from "./routes/RemixMemeRoute";
import MemeEditRoute from "./routes/EditMemeRoute";
import RedirectMeme from "./routes/RedirectMeme";

function AppRoutes() {
        const auth = useAuth();
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:slug" element={<RedirectMeme />} />
            <Route path="/about" element={<About />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="trending-memes" element={<TrendingMemes />} />
            <Route path="top-memes" element={<TopMemes />} />
            <Route path="topics" element={<Topics />} />
            <Route path="topics/:slug" element={<Topic />} />
            <Route path="view/:slug" element={<MemeRoute />} />
                <Route path="edit/:id" element={auth.isLoggedIn() && auth.user ? <MemeEditRoute /> : <NotFound />} />
            <Route path="search" element={<SearchRoute />} />
            <Route path="profile/:username" element={ <ProfileRoute /> } />
            <Route path="profile" element={ auth.isLoggedIn() && auth.user ? auth.user.isActive ? <ProfileEdit /> : <BlockedUser /> : <Login /> } />
            <Route path="upload-meme" element={<UploadMemeRoute />} />
            <Route path="community-guideline" element={<CommunityGuideline />} />
            <Route path="copyright" element={<Copyright />} />
            <Route path="manage-cookies" element={<ManageCookies />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route path='*' element={<NotFound />} />
            <Route path='not-found' element={<NotFound />} />
                <Route path='blocked-user' element={<BlockedUser />} />
                <Route path='remix/:slug' element={auth.isLoggedIn() && auth.user ? <RemixMemeRoute /> : <NotFound />} />
            <Route path="leaderboard" element={<LeaderboardRoute />} />
        </Routes>
    );
}

export default AppRoutes;