import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {useAuth} from "./AuthProvider";
import {setToLocalStorage} from "../../util/helpers";
import ProfileForm from "./ProfileForm";

// Styles
import "../../styles/forms.scss";

const SignupForm = ({step, email}) => {
	const initialState = { email: "", password: "", confirmPassword: "", terms: "", isSubmitting: false, errorMessage: ""},
		[data, setData] = useState(initialState),
		[formStep, setFormStep] = useState(step),
		navigate = useNavigate(),
		auth = useAuth();

	const handleInputChange = (event)=> {
		setData({
			...data,
			email: email,
			[event.target.name]: event.target.value
		});
	};

	const handleSignup = (event) => {
		event.preventDefault();

		// Check for password confirm
		if (data.password !== data.confirmPassword) {
			setData({
				...data,
				errorMessage: "Passwords don't match"
			});

			return;
		}

		// Check for terms checked
		if (!data.terms || data.terms ==="") {
			setData({
				...data,
				errorMessage: "You need to accept our terms"
			});

			return;
		}

		setData({
			...data,
			isSubmitting: true,
			errorMessage: ""
		});

		{
			// Generate username
			const matches = email.match(/^(.*)@/);
			const username = 
				matches ? 
					matches[1].replace(/[^\w]/gmi, '') + ( 100 + Math.floor(Math.random() * 899) ) :
					'timestop' + (new Date().getTime());

			auth.signup(data.email, data.password, username)
			.then(function (response) {
				if (response.data && response.status === 200) {
					setToLocalStorage("key", response.data.apikey, 7 * 24 * 60 * 60 * 1000);
					auth.getUser(response.data.apikey);
					setFormStep(3);
				}
			})
			.catch((err) => {
				console.log(err);
				setData({
					...data,
					isSubmitting: false,
					errorMessage: "Error logging in"
				});
			});
		}
	};

	if (formStep === 3) {
		return (
			<ProfileForm />
		)
	}

	return (
		<div className="form form-email">
			<img className="back-to-previous" onClick={() => navigate(-1)} src="/images/arrow-back.svg" alt="Go back"/>
			<h2>Memes for <span>every mood</span></h2>
			<div>
				<p>Sign up with email</p>
				{
					!data.errorMessage || data.errorMessage === "" ?
						null
						:
						<div className="form-error-message">
							{data.errorMessage}
						</div>
				}
				<form onSubmit={handleSignup}>
					<div className="form-email-main">
						<label>Email</label>
						<input readOnly type="email" name="email" value={email ? email : ""} placeholder="Enter your email" required/>
					</div>
					<div className="form-password-main">
						<label>Password</label>
						<input onChange={handleInputChange} name="password" type="password" placeholder="Enter your password" required/>
					</div>
					<div className="form-password-main">
						<label>Confirm Password</label>
						<input onChange={handleInputChange} name="confirmPassword" type="password" placeholder="Enter your password" required/>
					</div>
					<div className="form-checkbox-main">
						<input onChange={handleInputChange} value="checked" name="terms" type="checkbox" />
						I hearby agree to the <Link to="/terms-of-service">Terms and conditions</Link>
					</div>
					<button className="form-submit btn btn-colored btn-full">
						{data.isSubmitting ? (
							"Creating account..."
						) : (
							"Create account"
						)}
					</button>
				</form>
			</div>
		</div>
	);
}

export default SignupForm;