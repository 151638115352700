import { useState, useEffect } from "react";
import { convertToSlug, getFromLocalStorage} from "../../util/helpers";
import {useAuth} from "../Auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Styles
import "./Meme.scss";
import "./../../styles/forms.scss";

// Types
const EditMeme = ({ id }) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const initialState = { title: "", tags: [], caption: "", source: "", isUpdating: false, successMessage: "", errorMessage: ""},
		[data, setData] = useState(initialState),
		[meme, setMeme] = useState([]),
		[tags, setTags] = useState([]);

	useEffect(() => {
		if (id) {
			axios.get(`${process.env.REACT_APP_API_BASE_URL}/memes/${id}`, {})
				.then(result => {
					if (result) {
						if (auth?.user && auth?.user.id !== result.data?.contributor.id) {
							navigate({
								pathname: '/not-found'
							});
						}

						setTags(result.data.tags);
						setMeme(result.data);
						setData({
							...data,
							tags: tags,
							title: result.data.title,
							caption: result.data.caption ?? "",
							source: result.data.source ?? ""
						});
					}
				});
		}
	}, []);

	function handleInputChange(event) {
		setData({
			...data,
			tags: tags,
			[event.target.name]: event.target.value
		});
	}

	function handleMemeUpdate(event) {
		event.preventDefault();
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/memes/${id}`, {
			title: data.title,
			source: data.source,
			tags: tags,
			caption: data.caption,
		}, {
			headers: {
				'x-api-key': getFromLocalStorage("key"),
				'Content-Type': 'application/json'
			}
		})
			.then(function (response) {
				if (response.data) {
					setData({
						...data,
						successMessage: "Meme successfully updated"
					});

					navigate({
						pathname: `/view/${convertToSlug(meme?.title)}-${meme?.id}`
					});
				}
			})
			.catch((err) => {
				setData({
					...data,
					errorMessage: err
				});
			});
	}

	function handleKeyDown(e) {
		// If user did not press enter key, return
		if(e.key !== 'Enter') return
		// Get the value of the input
		const value = e.target.value;
		// If the value is empty, return
		if(!value.trim()) return
		// Add the value to the tags array
		setTags([...tags, value]);
		// Clear the input
		e.target.value = '';
	}

	function removeTag(index){
		setTags(tags.filter((el, i) => i !== index))
	}

	return (
		<section className="meme meme-upload">
			<div className="container">
				<div className="grid grid-meme-upload">
					<div className="ad">
						<img width={300} height={600} src="/images/example-ad.jpg" alt="Example ad" />
					</div>
					<div className="meme-main">
						<div className="meme-heading">
							<h1>Edit your meme</h1>
						</div>
						{
							!data.errorMessage || data.errorMessage === "" ?
								null
								:
								<div className="form-error-message">
									{ data.errorMessage }
								</div>
						}
						{
							!data.successMessage || data.successMessage === "" ?
								null
								:
								<div className="form-success-message">
									{ data.successMessage }
								</div>
						}
						<div className="grid grid-meme-form">
							<div className="meme-uploader-notice">
								<img className="meme-image-main" src={meme?.url} alt="" />
							</div>
							<div className="form form-text">
								<form onSubmit={handleMemeUpdate}>
									<div className="form-text-main">
										<label>Title</label>
										<input onChange={handleInputChange} name="title" value={data?.title} type="text"
										       placeholder="Add a helpful title" required autoFocus/>
									</div>
									<div className="form-text-main tag-selector">
										<label>Tags (press enter to add new tag)</label>
										<div>
											<div className="tag-item-container">
												{ tags.map((tag, index) => (
													<div className="tag-item" key={index}>
														<span className="text">{tag}</span>
														<span className="close" onClick={() => removeTag(index)}>&times;</span>
													</div>
												)) }
											</div>
											<input onKeyDown={(e) => {
												handleKeyDown(e)
											}} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} type="text" className="tags-input" placeholder="Type something" />
										</div>
									</div>
									<div className="form-textarea-main">
										<label>Source</label>
										<textarea onChange={handleInputChange} name="source" value={data?.source} placeholder="Meme source"/>
									</div>
									<button className="form-submit btn btn-colored btn-full" disabled={data.isUpdating}>Save Edit</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default EditMeme;