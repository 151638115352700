import * as React from "react";
import Layout from "../../components/Layout/Layout";
import Search from "../../components/Search/Search";
import {Link, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

const NotFound = () => {
	const location = useLocation();

	return (
		<>
			<Helmet>
				<title>User Blocked | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="User Blocked | Zikoko! Memes"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<Layout>
				<Search />
				<main>
					<div className="container">
						<section className="grid not-found">
							<div className="not-found-content">
								<h2>User Blocked</h2>
								<p>This user has been blocked for violating our <Link to="/community-guideline">community guidelines.</Link></p>
								<a href="mailto:memes@bigcabal.com?subject=Appeal community guideline violation" className="btn btn-multicolored">Appeal</a>
							</div>
							<img src="https://meme-dev.s3.eu-west-1.amazonaws.com/uploads/2021/4/12/1618191495044-8583/IMG_20201123_025747.jpg" alt="Not found" />
						</section>
					</div>
				</main>
			</Layout>
		</>
	);
}

export default NotFound;
