import {useMemo, useEffect, useState} from "react";
import EmailForm from "../../components/Auth/EmailForm";
import {isEmail} from "../../util/helpers";
import LoginForm from "../../components/Auth/LoginForm";
import {useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import axios from "axios";

const Login = () => {
	const [verified, setVerified] = useState(false);
	const query = useQuery();
	const email = query.get("email");
	const verify = query.get("verify");
	const redirect = query.get("redirect");
	const verificationKey = query.get("key");
	const location = useLocation();
	const navigate = useNavigate();

	useEffect( () => {
		if (verify && verificationKey) {
			axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/verify`, {
				email: verify,
				key: verificationKey
			})
				.then(function (response) {
					if (response) {
						setVerified(!verified);
						navigate({ pathname: '/' });
					}
				})
		}
	}, []);

	if (verified) {
		navigate({ pathname: '/' });
	}

	if (email && isEmail(email)) {
		return (
			<main className="auth">
				<div className="container container-auth">
					<LoginForm email={email} redirect={redirect} step={2} />
				</div>
			</main>
		)
	}

	function useQuery() {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	return (
		<>
			<Helmet>
				<title>Log in | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<meta property="og:title" content="Zikoko! Memes | There's a meme for that and Zikoko! has it"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<main className="auth">
				<div className="container container-auth">
					<EmailForm redirect={redirect} />
				</div>
			</main>
		</>
	);
}

export default Login;