/**
 * Name: Footer component
 * Description: Site wide footer
 * Author: omeiza@bigcabal.com
 */

// Styles
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
	return (
		<footer className="footer">
			<div className="container">
				<div className="grid grid-footer">
					<div className="footer-copyright">
						© 2022 Z! Memes
					</div>
					<ul className="footer-links">
						<li><Link to="/terms-of-service">Terms of Service</Link></li>
						<li><Link to="/community-guideline">Community Guidelines</Link></li>
						<li><Link to="/privacy-policy">Privacy Policy</Link></li>
						<li><Link to="/copyright">Copyright</Link></li>
						<li><Link to="/manage-cookies">Manage Cookies</Link></li>
					</ul>
				</div>
			</div>
		</footer>
	)
}

export default Footer;