import * as React from "react";
import Layout from "../components/Layout/Layout";
import Search from "../components/Search/Search";
import TopAll from "../components/Top/TopAll";
import AdContext from "../components/Ad/AdContext";
import Ad from "../components/Ad";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const TopMemes = () => {
	const location = useLocation();
	const collectionStructuredData = {
		"@context": "https://schema.org/",
		"@type": "CollectionPage",
		"name": "Top Memes",
		"mainEntityOfPage": {
			"@type": "WebPage",
			"@id": process.env.REACT_APP_BASE_URL + location.pathname
		},
		"headline": "Zikoko Memes Top Memes",
		"description": "Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans.",
	}

	return (
		<>
			<Helmet>
				<title>Top Memes | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="Top Memes | Zikoko! Memes"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
				<script type="application/ld+json">{JSON.stringify(collectionStructuredData)}</script>
			</Helmet>
			<Layout>
				<AdContext>
					<Search />
					<Ad id="AFTER_SEARCH" type="leaderboard" />
					<main>
						<TopAll />
					</main>
					<Ad id="BEFORE_FOOTER" type="leaderboard" />
				</AdContext>
			</Layout>
		</>
	);
}

export default TopMemes;
