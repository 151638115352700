import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {convertToSlug, getFromLocalStorage, responsiveImages} from "../../util/helpers";
import axios from 'axios';
import { useApiRequest } from "../../lib/api";

import './index.scss';

import Masonry from "react-masonry-css";

const PlaceholderItem = ({item}) => {
	return (<div className="placeholder"></div>);
};

export const MemeItem = ({item}) => {
	const srcSet = responsiveImages(item);

	if (!srcSet) {
		return (
			<Link to={`/view/${convertToSlug(item.title)}-${item.id}`}>
				<div className="thumb"><img loading="lazy" src={item.url} alt={`${item.title}`}/></div>
			</Link>
		);
	}

	return (
		<Link to={`/view/${convertToSlug(item.title)}-${item.id}`}>
			<div className="thumb">
				<img
					loading="lazy"
					src={item.url}
					srcSet={`${srcSet}`}
					onError={(e) => { e.target.removeAttribute('srcset') }}
					alt={`${item.title}`}
				/>
			</div>
		</Link>
	);
};

export const ArticleHandler = ({item}) => {
	if (item._embedded?.self?.[0]) item = item._embedded.self[0];
	return (
		<a href={item.link} target="_blank" rel="noreferrer">
			<div className="thumb" dangerouslySetInnerHTML={{__html: item.thumbnail }}/>
			<div className="label">{item.title.rendered}</div>
		</a>
	);
};

export const GridListing = ({ 
		items, bigFirst = false, ItemHandler = MemeItem, className = null, columns = null, 
		mobileColumns = null, count = 50, scroller = false, masonry = false, 
		endPoint, args = {}, autoload=false, placeholderCount = null,
		notFound = null, 
		// Send authorisation headers or not. Default not but can be meaningful in some cases, e.g.
		// for when the return depends on logged in state
		authorise = false,
	}) => {
    const classNames = [ 'grid-listing' ];
    if (bigFirst) classNames.push('big-first');
	if (className) classNames.push(className);
	if (columns) classNames.push('columns-' + columns);
	if (mobileColumns) classNames.push('mobile-columns-' + mobileColumns);
	if (scroller) classNames.push('scroller');

	const initialEntriesArgs = { ...args, count, page: 1 };
	const initialEntriesUrl = (endPoint) ? 
		process.env.REACT_APP_API_BASE_URL + endPoint +
			'?' + Object.keys(initialEntriesArgs).filter(key => initialEntriesArgs[key]).map(key => `${key}=${encodeURIComponent(initialEntriesArgs[key])}`).join('&') 
		:
		null;

	const [initialEntries] = useApiRequest(initialEntriesUrl, { 
		useAuth: authorise, serverOnly: true, initialValue: [],
		transform: data => data?.memes ?? [],
	 });
	const [entries, setEntries] = useState(initialEntries);
	const [doGetMore, setDoGetMore] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [activeArgs, setActiveArgs] = useState(null);
	const [activeEndpoint, setActiveEndpoint] = useState(null);

	useEffect(() => {
		if (!endPoint) return;
		if (activeArgs && activeEndpoint) {
			if (activeEndpoint === endPoint &&
				JSON.stringify(activeArgs) === JSON.stringify(args)) return;
		}
		setActiveEndpoint(endPoint);
		setActiveArgs(args);
	}, [args, endPoint]);

	useEffect(() => {
		if (!activeArgs) return;
		console.log("Reload", activeEndpoint, activeArgs);
		setEntries([]);
		setDoGetMore(true);
	}, [activeArgs, activeEndpoint]);
	
	useEffect(() => {
		if (!endPoint) return;
		if (!doGetMore) return;
		setDoGetMore(false);

		if (entries.length % count) return; // No more results as last page wasn't a round count entries
		if (isLoading) return;
		setIsLoading(true);
		const page = (entries.length / count) + 1;

		let url = process.env.REACT_APP_API_BASE_URL + endPoint;
		const reqArgs = { ...activeArgs, count, page };
		url = url + '?' + Object.keys(reqArgs).filter(key => reqArgs[key]).map(key => `${key}=${encodeURIComponent(reqArgs[key])}`).join('&');

		const params = {
			headers: {},
		};
		if (authorise) params.headers['x-api-key'] = getFromLocalStorage('key');
		console.log('GridLoad', url, params);

		axios.get(url, params).then(res => {
			if (res.status === 200) {
				const rows = res.data.memes ?? res.data.rows ?? [];
				setEntries([...entries, ...rows]);
				setIsLoading(false);
			}
		});
	}, [doGetMore]);

	useEffect(() => {
		if (!endPoint) return;
		// setDoGetMore(true);
		if (!autoload) return;
		const eventListener = window.addEventListener('scroll', (evt) => {
			if (isLoading) return;
			if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight) {
				setDoGetMore(true);
			}
		});
		return () => { window.removeEventListener('scroll', eventListener) };
	}, [endPoint]);

	if (masonry) {
		classNames.push('masonry');
		if (entries.length === 0 && notFound) {
			return notFound;
		}	
		return (
			<ul className={classNames.join(' ')}>
				<Masonry
					breakpointCols={{
						default: 4,
						1199: 2
					}}
					className="masonry-inner"
					columnClassName="partition"
					component="UL"
					>
					{ entries.map((item, idx) => (<li key={item.id}><ItemHandler key={item.id} item={item} position={idx + 1}/></li>)) }
				</Masonry>
			</ul>
		);
	}
	const displayEntries =  [ ...(items ?? []), ...entries ];
	let placeholderId = 0;
	while (placeholderCount && placeholderCount > displayEntries.length) {
		displayEntries.push({
			id: `placeholder-${++placeholderId}`,
			placeholder: true,
		})
	}

	if (displayEntries.length === 0 && notFound) {
		return notFound;
	}
	
    return (
        <ul className={classNames.join(' ')}>
            { displayEntries.map((item, idx) => {
				const Handler = item.placeholder ? PlaceholderItem : ItemHandler;
				return <li key={item.id}><Handler key={item.id} item={item} position={idx + 1}/></li>;
			}) }
        </ul>
    );
};

export default GridListing;
