import { DFPSlotsProvider } from 'react-dfp';

const AdContext = ({ children }) => {
	const networkID = '24669334';

	return (
		<DFPSlotsProvider
			dfpNetworkId = { networkID }
			adSenseAttributes={{'page_url': 'meme2.zikoko.com'}}
			sizeMapping={[
				{ viewport: [1200], sizes: [[728, 90], [300, 600]] },
				{ viewport: [500], sizes: [[320, 50]] },
				{ viewport: [320], sizes: [[300, 250], 'fluid'] },
			]}
		>
			{ children }
		</DFPSlotsProvider>
	)
}

export default AdContext