import { useEffect, useState, useRef, useContext } from 'react';
import { GridListing, ArticleHandler, MemeItem } from '../GridListing';
import Context from '../../context';
export { ArticleHandler, MemeItem };

export const MediaTabs = {
    All: {
        args: { mimeType: undefined },
        default: true,
    },
    GIFs: {
        args: { mimeType: 'image/gif' },
    },
    Images: {
        args: { mimeType: 'image/still' },
    },
    _default: 'All',
};
 
export const ListSection = (props) => {
    const { title, pageClassName, scroller = false, tabs = null } = props;
    const { isServer } = useContext(Context);
     
    const [tab, setTab] = useState(tabs?._default);
    const [containerScrollEventHandler, setContainerScrollEventHandler] = useState(null);
    const [listingProps, setListingProps] = useState(props);
    const [isReady, setIsReady] = useState(isServer ? true : false);
    const container = useRef(null);
    const arrowLeft = useRef(null);
    const arrowRight = useRef(null);

    useEffect(() => {
        if (!tabs || tab === null) {
            setIsReady(true);
            return;
        }
        if (tabs[tab].endPoint) {
            setListingProps({ ...listingProps, ...(tabs[tab]) });
            setIsReady(true);
            return;
        }
        if (tabs[tab].args) {
            setListingProps({ ...listingProps, args: { ...(props.args ?? {}), ...(tabs[tab].args) }});
        }
        if (tabs[tab].items) {
            setListingProps({ ...listingProps, items: tabs[tab].items });
        }
        setIsReady(true);
    }, [tab, tabs]);
     
     const scroll = (direction) => {
        const elem = container.current;
        const grid = elem.querySelector('.grid-listing');
        let dest = grid.scrollLeft + direction * grid.clientWidth;
        dest = Math.min(Math.max(0, dest), grid.scrollWidth - grid.clientWidth);
        console.log(direction, elem, dest);
        grid.scrollLeft = dest;
     };

     useEffect(() => {
        if (container.current && !containerScrollEventHandler) {
            const grid = container.current.querySelector('.grid-listing');
            const h = grid.addEventListener('scroll', e => {
                console.log(grid.scrollLeft);
                arrowLeft.current?.classList.toggle('disabled', grid.scrollLeft === 0);
                arrowRight.current?.classList.toggle('disabled', grid.scrollLeft >= grid.scrollWidth - grid.clientWidth);
            });
            setContainerScrollEventHandler(h);
        }
     }, [container]);

     return (
         <section className={pageClassName + " list-section"}>
             <div className="container" ref={container}>
                 <div className="heading-section">
                    { title ? 
                     <div className="heading-title">
                         { title instanceof Function ? title() : title }
                     </div> : null }
                     {
                        tabs ? (
                            <ul className="filters">
                                {Object.keys(tabs).filter(x => x !== '_default').map(x => {
                                    return (
                                        <li key={x} onClick={e => setTab(x)} className={x === tab ? 'active' : null}>{x}</li>
                                    );
                                })}
                            </ul>
                        ) : null
                     }
                     { scroller ? (
                        <ul className="scroll-arrows">
                            <li onClick={e => scroll(-1)} ref={arrowLeft} className="disabled" key="left"><img src="/images/scroll-left-icon.svg" alt="scroll left" /></li>
                            <li onClick={e => scroll(+1)} ref={arrowRight} key="right"><img src="/images/scroll-right-icon.svg" alt="scroll right" /></li>
                        </ul>                    
                     ) : null }
                 </div>
                 { isReady ? <GridListing { ...listingProps } /> : <GridListing/> }
             </div>
         </section>
     )
 }

 export default ListSection;