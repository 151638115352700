import * as React from "react";
import Layout from "../../components/Layout/Layout";
import Search from "../../components/Search/Search";
import {Link, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

const NotFound = () => {
	const location = useLocation();

	return (
		<>
			<Helmet>
				<title>Page not found | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="Page not found | Zikoko! Memes"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<Layout>
				<Search />
				<main>
					<div className="container">
						<section className="grid not-found">
							<div className="not-found-content">
								<h2>Error 404? Ah, Omo it’s like that page has lost o!</h2>
								<p>We honestly actually searched o but we can’t find it. Shey you’ll not go <Link to="/">back home</Link>, or to another page.</p>
								<Link to="/" className="btn btn-multicolored">Go back home</Link>
							</div>
							<img src="/images/404-image.jpg" alt="Not found" />
						</section>
					</div>
				</main>
			</Layout>
		</>
	);
}

export default NotFound;
