import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

// Styles
import "./Meme.scss";

const MemeUploadedPopup = (props) => {
	useEffect(() => {
		document.body.style.overflow = "hidden";
	}, []);

	return (
		<div className="overlay">
			<div className="meme-uploader">
				<div className="meme-report-heading">
					<h2>🎉 Upload Successful!</h2>
					<img onClick={props.closeMemeSuccessPopup} src="/images/close-icon.svg" className="menu-close" alt="Close Menu" />
				</div>
				<div className="meme-report-details meme-report-submitted meme-upload-success">
					<img src="/images/success-upload-meme.jpg" alt="Upload meme success"/>
					<h2>+5 Points for you!</h2>
					<p>Your meme has been successfully uploaded and sent for approval. The points reflect on your page only after meeting approval criteria.</p>
					<Link to={`/view/${props.memeURL}`} className="btn btn-colored btn-full">Preview your meme</Link>
					<Link to='/upload-meme'><div className="meme-uploader-more">Upload another meme</div></Link>
				</div>
			</div>
		</div>
	)
}

export default MemeUploadedPopup;