import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import {ScrollToTop} from "./util/helpers";

import App from './App';

if (true) {
    const root = ReactDOM.createRoot(
        document.getElementById('root')
    );

    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <ScrollToTop />
                <App/>
            </BrowserRouter>
        </React.StrictMode>
    );
} else {
    ReactDOM.hydrate(
        <React.StrictMode>
            <BrowserRouter>
                <ScrollToTop />
                <App/>
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById('root')
    );
}