/**
 * Name: Layout component
 * Author: omeiza@bigcabal.com
 */

import * as React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

// Styles
import "../../styles/typography.scss";
import "../../styles/base.scss";
import "../../styles/grid.scss";
import "./Layout.scss";

const Layout = ({ children }) => {
	React.useEffect(() => {
		document.body.style.overflow = "scroll";
	}, []);

	return (
		<div>
			<Header />
			{ children }
			<Footer />
		</div>
	)
}

export default Layout
