import React, { useState, useEffect, useContext } from "react";
import AuthContext from "./AuthContext";
import {getCookie, getFromLocalStorage, setToLocalStorage} from "../../util/helpers";
import axios from "axios";

const KEY_VALIDITY = 7 * 24 * 60 * 60 * 1000;


export const AuthProvider = ({ isServer, children }) => {
	const [user, setUser] = useState(null);
	const [key, setKey] = useState(isServer ? null : getFromLocalStorage("key"));

	useEffect(() => {
		if (key) {
			getUser(key);
		}

		const keyFromCookie = getCookie("ZKK_key");
		if (keyFromCookie) {
			setToLocalStorage("key", keyFromCookie, KEY_VALIDITY);
			getUser(keyFromCookie);
		}
	}, [key]);

	function getUser(apiKey) {
		return axios.get(`${process.env.REACT_APP_API_BASE_URL}/users`, {
			headers: {
				'x-api-key': apiKey
			}
		})
			.then(function (response) {
				if (response.data && response.status === 200) {
					setUser(response.data);
					setToLocalStorage("user", response.data, KEY_VALIDITY);
				}
			})
			.catch((err) => {
				if (err.response.status === 404) {
					localStorage.clear();
				}
			});
	}

	async function login(email, password) {
		const promise = axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
			email: email,
			password: password
		});
		promise.then(res => {
			setToLocalStorage("key", res.data.apikey, KEY_VALIDITY);
			setKey(res.data.apikey);
		});
		return promise;
	}

	async function signup(email, password, username) {
		return axios.put(`${process.env.REACT_APP_API_BASE_URL}/signup`, {
			email: email,
			password: password,
			username: username
		});
	}

	function logout() {
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.REACT_APP_API_BASE_URL}/logout`, {
				headers: {
					'x-api-key': getFromLocalStorage("key"),
					'Content-Type': 'application/json'
				}
			})
			.then(function (response) {
				localStorage.clear();
				resolve(true);
			})
			.catch(err => {
				reject(err);
			});
		});
	}

	function isLoggedIn() {
		return user && getFromLocalStorage("key");
	}

	return (
		<AuthContext.Provider value={{ user, getUser, login, signup, logout, isLoggedIn }}>{children}</AuthContext.Provider>
	)
}

export const useAuth = () => {
	return useContext(AuthContext)
}