/**
 * Name: Hot Topic component
 * Description: Hot Topic section
 * Author: omeiza@bigcabal.com
 */

// Styles
import "./Hot.scss";
import {Link} from "react-router-dom";
// import {convertToSlug} from "../../util/helpers";
import Masonry from "react-masonry-css";

// Types
const HotSingle = ({ data }) => {

	// This is dummy. The data prop will be used when admin is ready
	const dummyData = [
		{ title: "Abc", link: "/", url: "/images/5f07dd8cfe8ff862b56d528a3.jpg" },
		{ title: "Abc", link: "/", url: "/images/13672844371 1.jpg" },
		{ title: "Abc", link: "/", url: "/images/5f9ebe1dc526e2059e38ab5a1.jpg" },
		{ title: "Abc", link: "/", url: "/images/5fa0c771c526e2059e38abe61.jpg" },
		{ title: "Abc", link: "/", url: "/images/5ffe03ef224e8e333d44542c2.jpg" },
		{ title: "Abc", link: "/", url: "/images/5fa4cc1cc526e2059e38ad3a1.jpg" },
		{ title: "Abc", link: "/", url: "/images/61e7e95a21244f578bcf461a1.jpg" },
		{ title: "Abc", link: "/", url: "/images/603c64d9db89d721875ce5901.jpg" },
		{ title: "Abc", link: "/", url: "/images/5efefd2afe8ff862b56d520a1.jpg" },
		{ title: "Abc", link: "/", url: "/images/5f8d9007fe8ff862b56d55031.jpg" },
		{ title: "Abc", link: "/", url: "/images/5ff46829224e8e333d4453b81.jpg" },
		{ title: "Abc", link: "/", url: "/images/aki.gif" },
		{ title: "Abc", link: "/", url: "/images/5fa4bc18c526e2059e38ad121.jpg" },
		{ title: "Abc", link: "/", url: "/images/61e7e95a21244f578bcf461a1.jpg" },
		{ title: "Abc", link: "/", url: "/images/603c64d9db89d721875ce5901.jpg" },
		{ title: "Abc", link: "/", url: "/images/5efefd2afe8ff862b56d520a1.jpg" },
		{ title: "Abc", link: "/", url: "/images/5f8d9007fe8ff862b56d55031.jpg" },
		{ title: "Abc", link: "/", url: "/images/5ffe03ef224e8e333d44542c2.jpg" },
		{ title: "Abc", link: "/", url: "/images/aki.gif" },
	];

	const memes = dummyData.map((meme) => {
		return (
			<li>
				<Link to={meme.link}><img src={meme.url} alt={meme.title} /></Link>
			</li>
		)
	});

	return (
		<section className="hot-single">
			<div className="container">
				<div className="heading-section">
					<div className="heading-title hot-heading-select">
						Pepper Dem
					</div>
					<div className="meme-type-list">
						<ul>
							<li><Link to="/trending-memes" className="active">All</Link></li>
							<li><Link to="/trending-memes">GIFs</Link></li>
							<li><Link to="/trending-memes">Images</Link></li>
						</ul>
					</div>
				</div>
				<div className="hot-single-items masonry">
					<ul className="grid grid-hot-all-masonry">
						<Masonry
							breakpointCols={4}
							className="masonry-grid"
							columnClassName="masonry-grid_column">
							{ memes }
						</Masonry>
					</ul>
				</div>
			</div>
		</section>
	)
}

export default HotSingle;