import {useState, useRef, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "./AuthProvider";
import axios from "axios";
import {getFromLocalStorage} from "../../util/helpers";

// Styles
import "../../styles/forms.scss";

const ProfileForm = () => {
	const uploadClick = useRef(null),
		auth = useAuth(),
		navigate = useNavigate(),
		[data, setData] = useState({ image: "", username: auth.user?.username, bio: "", isSubmitting: false, errorMessage: "" }),
		[thumb, setThumb] = useState(null),
		[signedData, setSignedData] = useState(null),
		[uploadFile, setUploadFile] = useState(null);

	useEffect(() => {
		if (uploadFile) {
			const reader = new FileReader();

			reader.onloadend = () => {
				setThumb(reader.result);

				// Now let's upload
				getSignedURL();
			};

			reader.readAsDataURL(uploadFile);
		}
	}, [uploadFile]);

	//@TODO Make dry
	function getSignedURL() {
		if (uploadFile) {
			const data = {
				"filename": uploadFile.name,
				"mimeType" : uploadFile.type
			}

			axios.post(`${process.env.REACT_APP_API_BASE_URL}/public/uploadUrl`, data, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then ((res) => {
					setSignedData(res.data);
				})
				.catch ((error) => {
					console.error(error);
				})
		}
	}

	const handleUpload = async () => {
		const formData = new FormData(),
			origin = new URL(document.location.href);
		origin.pathname = '';
		origin.search = '';

		if (uploadFile && signedData) {
			formData.append("Content-Type", uploadFile.type);
			Object.entries(signedData.uploadData.fields).forEach(([k, v]) => {
				formData.append(k, v);
			});

			formData.append("file", uploadFile);
			return axios.post(signedData.uploadData.url, formData, {
				headers: {
					"Access-Control-Allow-Origin" : "*",
					// "Origin": origin,
				}
			});
		}
	}

	const handleImageSelection = (event) => {
		const files = event.currentTarget.files;
		if (files) {
			setUploadFile(files[0]);
		}
	}

	const handleInputChange = (event)=> {
		setData({
			...data,
			[event.target.name]: event.target.value
		});
	};

	const handleProfileUpdate = async (event) => {
		event.preventDefault();

		setData({
			...data,
			isSubmitting: true,
			errorMessage: ""
		});

		// Wait for final part of image upload
		await handleUpload();

		axios.post(`${process.env.REACT_APP_API_BASE_URL}/users`, {
			username: (!data.username || data.username === "") ? auth.user?.username : data.username,
			bio: data.bio,
			image: signedData?.getUrl
		}, {
			headers: {
				'x-api-key': getFromLocalStorage("key")
			}
		})
			.then(() => {
				auth.getUser(getFromLocalStorage("key")).then(() => navigate({ pathname: '/' }));
			})
			.catch(err => {
				console.log(err);
				setData({
					...data,
					isSubmitting: false,
					errorMessage: "Error updating user"
				});
			})
	};

	return (
		<div className="form form-email">
			<img className="back-to-previous" onClick={() => navigate(-1)} src="/images/arrow-back.svg" alt="Go back"/>
			<h2>Profile Information</h2>
			<div>
				<p>Add these to complete your account</p>
				{
					!data.errorMessage || data.errorMessage === "" ?
						null
						:
						<div className="form-error-message">
							{ data.errorMessage }
						</div>
				}
				<form onSubmit={handleProfileUpdate}>
					<div className="form-upload-main" style={{backgroundImage: `url("${thumb}")`, backgroundSize: "cover"}}>
						<img src="/images/camera.svg" alt="Upload button" onClick={() => { const a = uploadClick.current; if (a) a.click(); }} />
						<input id="file" name="file" type="file" ref={uploadClick} onChange={handleImageSelection} />
					</div>
					<div className="form-email-main">
						<label>Username (We made up one for you, change it)</label>
						<input onChange={handleInputChange} type="text" name="username" value={auth.user?.username} />
					</div>
					<div className="form-textarea-main">
						<label>Add a Bio</label>
						<textarea onChange={handleInputChange} name="bio" placeholder="I speak 2 languages; Memes and meming. Nothing more."/>
					</div>
					<button className="form-submit btn btn-colored btn-full">
						{data.isSubmitting ? (
							"Updating..."
						) : (
							"Continue"
						)}
					</button>
				</form>
			</div>
		</div>
	);
}

export default ProfileForm;