import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {convertToSlug, getFromLocalStorage} from "../../util/helpers";
import Context from "../../context";
import {TagsInput} from "react-tag-input-component";
import MemeUploadedPopup from "./MemeUploadedPopup";

const RemixMeme = ({ meme }) => {
	const initialState = { title: "", tags: [], caption: "", source: "", isUploading: false, errorMessage: ""},
		[data, setData] = useState(initialState),
		[uploadedMemePopup, setUploadedMemePopup] = useState(false),
		[uploadedMemeURL, setUploadedMemeURL] = useState(null),
		[tags, setTags] = useState([]),
		// [source, setSource] = useState(null),
		[memeUrl, setMemeUrl] = useState(null),
		[isEditMode, setIsEditMode] = useState(true);

	useEffect(() => {
		if (meme && !isEditMode) {
			setTags(meme.tags);
			setData({
				...data,
				tags: tags,
				source: meme.source ?? ""
			});
		}
	}, [isEditMode]);

	function handleInputChange(event) {
		setData({
			...data,
			tags: tags,
			[event.target.name]: event.target.value
		});
	}

	function handleMemeUpload(event) {
		event.preventDefault();
		axios.put(`${process.env.REACT_APP_API_BASE_URL}/memes`, {
			title: data.title,
			tags: tags,
			source: data.source,
			caption: data.caption,
			url: memeUrl
		}, {
			headers: {
				'x-api-key': getFromLocalStorage("key"),
				'Content-Type': 'application/json'
			}
		})
			.then(function (response) {
				if (response.data) {
					const slug = `${convertToSlug(data.title)}-${response.data.id}`;
					setUploadedMemeURL(slug);
					setUploadedMemePopup(true);
				}
			})
			.catch((err) => {
				setData({
					...data,
					errorMessage: err
				});
			});
	}

	function toggleMemeUploadPopup() {
		setUploadedMemePopup(!uploadedMemePopup);

		if (uploadedMemePopup) {
			document.body.style.overflow = "scroll";
		}
	}

	const ImageEditor = () => {
		const [FilerobotImageEditor, setFilerobotImageEditor] = useState(null);
		const getCurrentImgData = useRef();
		const { isServer } = useContext(Context);


		useEffect(() => {
			if (isServer) return;
			import('react-filerobot-image-editor').then(mod => {
				setFilerobotImageEditor(mod);
			});


		}, []);

		if (isServer) return <div className="serverPlaceHolder"></div>;

		const closeImageEditor = () => {
			console.log("CLOSE I SAY!");
		};

		if (!meme) {
			return;
		}

		const onBeforeSave = imageFileInfo => {
			console.log("BEFORESAVE", imageFileInfo);
			const res = getCurrentImgData.current(imageFileInfo);
			fetch(res.imageData.imageBase64).then(
				x => x.blob().then(blob => {
					const uploadData = {
						"filename": "meme.jpg",
						"mimeType" : blob.type ?? "image/jpeg",
					};

					axios.post(`${process.env.REACT_APP_API_BASE_URL}/public/uploadUrl`, uploadData, {
						headers: { 'Content-Type': 'application/json' },
					}).then(res => {
						const data = res.data;
						const formData = new FormData();
						formData.append("Content-Type", uploadData.mimeType);
						Object.entries(data.uploadData.fields).forEach(([k, v]) => formData.append(k, v));
						formData.append('file', blob);
						axios.post(data.uploadData.url, formData, {})
							.then(res => {
								console.log('uploaded', data.getUrl, res);
								setMemeUrl(data.getUrl);
								setIsEditMode(false);
							}).catch(e => {
							console.log('upload err', e);
						});
					}).catch(err => {
						console.log("ERROR", err);
					})
				})
			).catch(e => {
				console.log('error', e);
			});
			return false;
		};

		const rearrangeURL = (url) => {
			let newURL = url;
			const urlArray = url.split("/meme-dev/");

			if (urlArray.length > 1) {
				const newURLArray = urlArray[0].split("s3.eu-west-1.amazonaws.com");
				newURL = `${newURLArray[0]}meme-dev.s3.eu-west-1.amazonaws.com/${urlArray[1]}`;
			}

			return newURL;
		}

		return (
			FilerobotImageEditor ?
				<FilerobotImageEditor.default
					source={rearrangeURL(meme.url)}
					onClose={closeImageEditor}
					onSave={() => {}}
					closeAfterSave={true}
					getCurrentImgDataFnRef={getCurrentImgData}
					onBeforeSave={onBeforeSave}
					Text={{ text: 'Zikoko Memes' }}
					Rotate={{ angle: 90, componentType: 'slider' }}
					//   tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
					tabsIds={[FilerobotImageEditor.TABS.ADJUST, FilerobotImageEditor.TABS.ANNOTATE]}
					defaultTabId={FilerobotImageEditor.TABS.ANNOTATE} // or 'Annotate'
					defaultToolId={FilerobotImageEditor.TOOLS.TEXT} // or 'Text'
				/>
				:
				<div className="imageEditorPlaceholder"></div>
		);
	}

	function handleKeyDown(e) {
		// If user did not press enter key, return
		if(e.key !== 'Enter') return
		// Get the value of the input
		const value = e.target.value;
		// If the value is empty, return
		if(!value.trim()) return
		// Add the value to the tags array
		setTags([...tags, value]);
		// Clear the input
		e.target.value = '';
	}

	function removeTag(index){
		setTags(tags.filter((el, i) => i !== index))
	}

	return (
		<section className="meme meme-upload">
			{
				isEditMode ?
					<div className="container" style={{width: '100%', height: '70vh'}}>
						<ImageEditor/>
					</div>
					:
					(
						<div className="container">
							<div className="grid grid-meme-upload">
								<div className="ad">
									<img width={300} height={600} src="/images/example-ad.jpg" alt="Example ad" />
								</div>
								<div className="meme-main">
									<div className="meme-heading">
										<h1>Upload your meme</h1>
									</div>
									<div className="grid grid-meme-form">
										{
											memeUrl ?
												<div className="meme-uploader-notice">
													<img className="meme-image-main" src={memeUrl} alt="" />
													{/* <p>Un-added meme images will be make available for 2 minutes.</p> */}
												</div>
												:
												<div className="meme-uploader-wrapper meme-upload-area">
													<button className="meme-uploader-button" type="button" onClick={() => {
														const element = document.querySelector(".meme-create-button");
														if (element) element.click();
													}}>Create a meme</button>
												</div>
										}
										<div className="form form-text">
											<form onSubmit={handleMemeUpload}>
												<div className="form-text-main">
													<label>Title</label>
													<input onChange={handleInputChange} name="title" type="text"
													       placeholder="Add a helpful title" required autoFocus/>
												</div>
												<div className="form-text-main tag-selector">
													<label>Tags (press enter to add new tag)</label>
													<div>
														<div className="tag-item-container">
															{ tags.map((tag, index) => (
																<div className="tag-item" key={index}>
																	<span className="text">{tag}</span>
																	<span className="close" onClick={() => removeTag(index)}>&times;</span>
																</div>
															)) }
														</div>
														<input onKeyDown={(e) => {
															handleKeyDown(e)
														}} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} type="text" className="tags-input" placeholder="Type something" />
													</div>
												</div>
												<div className="form-textarea-main">
													<label>Source</label>
													<textarea onChange={handleInputChange} name="source" value={data?.source} placeholder="Meme source"/>
												</div>
												<button className="form-submit btn btn-colored btn-full" disabled={data.isUploading}>Upload Meme</button>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					)
			}

			{ uploadedMemePopup && uploadedMemeURL ? <MemeUploadedPopup memeURL={uploadedMemeURL} closeMemeSuccessPopup={toggleMemeUploadPopup.bind(this)} /> : null }
		</section>
	)
}

export default RemixMeme;