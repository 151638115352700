import Layout from "../components/Layout/Layout";
import Profile from "../components/User/Profile";
import {useLocation, useParams} from "react-router-dom";
import React from "react";
import {Helmet} from "react-helmet";
import { useAuth } from "../components/Auth/AuthProvider";
import BlockedUser from "./pages/BlockedUser";

const ProfileRoute = () => {
	const auth = useAuth();
	const params = useParams();
	const location = useLocation();

	if (auth.isLoggedIn() && auth.user) {
		if (!auth.user.isActive && auth.user.username === params.username) return <BlockedUser />
	}

	return (
		<>
			<Helmet>
				<title>{`${params.username}, Creator at Zikoko! Memes`}</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content={`${params.username}, Creator at Zikoko! Memes`}/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<Layout>
				<main>
					<Profile username={params.username} />
				</main>
			</Layout>
		</>

	);
}

export default ProfileRoute;
