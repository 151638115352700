import React, { useContext } from "react";
import Layout from "../components/Layout/Layout";
import Search from "../components/Search/Search";
import Context from '../context';
import {Link} from "react-router-dom";
import GridListing from '../components/GridListing';
import Ad from "../components/Ad";
import AdContext from "../components/Ad/AdContext";
import {Helmet} from "react-helmet";
import Competition from "../components/Banner/Competition";
import {responsiveImages} from "../util/helpers";

const Section = ({className, title, moreLink, children}) => {
	return (
		<section className={className}>
			<div className="container">
				<div className="grid grid-heading heading-section">
					<h2 className="heading-title hot-heading-select">
						{ title instanceof Function ? title() : title }
					</h2>
					{ moreLink ? (
						<Link to={moreLink} className="btn btn-text-icon">
							See more
							<img src="/images/arrow-right.svg" alt="Link Icon" />
						</Link>
					) : null }
				</div>
				{children}
			</div>
		</section>
	);
}

const TopicItem = ({ item }) => {
	const srcSet = responsiveImages(item);

	if (!srcSet) {
		return (
			<a href={`/search?type=All&q=${encodeURIComponent(item.tags.join(' '))}`}>
				<div className="thumb"><img loading="lazy" src={item.image} alt={item.name} /></div>
				<div className="label">{item.name}</div>
			</a>
		);
	}

	return (
		<a href={`/search?type=All&q=${encodeURIComponent(item.tags.join(' '))}`}>
			<div className="thumb"><img loading="lazy" src={item.image} srcSet={`${srcSet}`} onError={(e) => { e.target.removeAttribute('srcset') }} alt={item.name} /></div>
			<div className="label">{item.name}</div>
		</a>
	);
};

const Home = () => {
	const { supportedCountries, currentCountry, setCurrentCountry } = useContext(Context);
	const pageStructuredData = {
		"@context": "https://schema.org/",
		"@type": "CollectionPage",
		"name": "Zikoko Memes",
		"mainEntityOfPage": {
			"@type": "WebPage",
			"@id": process.env.REACT_APP_BASE_URL
		},
		"headline": "There's a meme for that and Zikoko! has it",
		"description": "Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans.",
	}

	const trendingTitle = () => {
		return (
			<div className="trending-heading-select">
				Trending in
				<select value={currentCountry} onChange={e => setCurrentCountry(e.target.value)}>
					{ Object.keys(supportedCountries).map(country => (
						<option key={country} value={country}>{supportedCountries[country].name}</option>
					))}
				</select>			
			</div>
		);
	}

	return (
		<>
			<Helmet>
				<title>Zikoko! Memes | There's a meme for that and Zikoko! has it</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL} />
				<meta property="og:title" content="Zikoko! Memes | There's a meme for that and Zikoko! has it"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
				<script type="application/ld+json">{JSON.stringify(pageStructuredData)}</script>
			</Helmet>
			<Layout>
				<AdContext>
					<Search />
					<Competition />
					<main>
						<Section className="trending" title={trendingTitle} moreLink="/trending-memes">
							<GridListing endPoint={`/memes/trending/${currentCountry ?? ""}`} count={12} placeholderCount={12}/>
						</Section>
						<Ad id="AFTER_TRENDING" type="leaderboard" />
						<Section className="top" title="Top Memes" moreLink="/top-memes">
							<GridListing endPoint='/memes' args={{orderBy:'likeCount', order:'desc'}} bigFirst={true} columns="5" count={12} placeholderCount={12}/>
						</Section>
						<Ad id="AFTER_TOP_MEMES" type="leaderboard" />
						<Section className="hot" title="Hot Topics" moreLink="/topics">
							<GridListing endPoint="/memes/topics" ItemHandler={TopicItem} columns="4" className="label-overlay" count={12} placeholderCount={12}/>
						</Section>
						<Ad id="AFTER_HOT_TOPICS" type="leaderboard" />
					</main>
				</AdContext>
			</Layout>
		</>
	);
}

export default Home;
