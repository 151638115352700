import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import {Link, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

const About = () => {
	const location = useLocation();
	const initialState = { name: "", errorMessage: ""},
		[data, setData] = useState(initialState),
		[email, setEmail] = useState(''),
		[subscribed, setSubscribed] = useState(false);

	const pageStructuredData = {
		"@context": "https://schema.org/",
		"@type": "CollectionPage",
		"name": "Zikoko Memes",
		"mainEntityOfPage": {
			"@type": "WebPage",
			"@id": process.env.REACT_APP_BASE_URL + location.pathname
		},
		"headline": "About Zikoko! Memes",
		"description": "Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans.",
	}

	// Subscribe user to newsletter via mailerlite
	const handleSubscribe = (event) => {
		event.preventDefault();

		axios.post(`${process.env.REACT_APP_API_BASE_URL}/newsletterSubscribe`, {
			email: data.email
		}, {
			headers: {
				'Content-Type': 'application/json',
			}
		})
			.then(function (response) {
				if (response.data) {
					setSubscribed(!subscribed);
					setEmail('');
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function handleInputChange(event) {
		setEmail(event.target.value);
		setData({
			...data,
			[event.target.name]: event.target.value
		});
	}

	return (
		<>
			<Helmet>
				<title>About | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="About | Zikoko! Memes"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
				<script type="application/ld+json">{JSON.stringify(pageStructuredData)}</script>
			</Helmet>
			<Layout>
				<main className="about">
					<section className="grid about-intro-wrapper">
						<div className="about-intro">
							<div className="about-intro-main">
								<h1>The Largest Repository of <span className="about-intro-variable">Black + African</span> <span className="about-intro-colored">Memes.</span></h1>
								<p>Zikoko Memes is your go to bank of relatable and culturally relevant memes, created by and for black people and Africans.</p>
								<Link to="/signup" className="btn btn-colored btn-full">Become a creator</Link>
								<Link to="/" className="btn btn-white btn-full">Explore Memes</Link>
							</div>
						</div>
						<div className="about-jumbotron">
							<img className="at-desktop" src="/images/about-back.jpg" alt="About Z! Memes" />
							<img className="at-mobile" src="/images/about-back-mobile.jpg" alt="About Z! Memes" />
						</div>
					</section>
					<section className="about-content">
						<div className="container">
							<div className="grid about-content-wrapper">
								<div className="about-content-main">
									<span>Why Z! Memes?</span>
									<h2>“The internet speaks Memes”</h2>
									<p>With over 4.5 billion active internet users and over 6,000 languages and dialects spoken worldwide, memes provide us with a universal language whether it’s Nollywood snapshots, GIFs or stickers.</p>
									<p>Movies and social media have given us some of the most hilarious memes and gifs back-to-back. From fainting governors to Sola Sobowale’s deadly expressions on King of Boys, imagine a world where all the most hilarious Nigerian and African memes on the internet can be found in one place.</p>
								</div>
								<div className="about-content-img">
									<img width={300} src="/images/about-content-img.png" alt="Shola Shobowale screaming" />
								</div>
							</div>

						</div>
					</section>
					<section className="about-description">
						<div className="container">
							<div className="about-description-content">
								<h2>Z!Memes allows you to <span>find, organize</span> and <span>contribute</span> to the most popular/trending African memes.</h2>
								<div className="about-value-list">
									<ul>
										<li>
											<img src="/images/send-icon.svg" alt="Share"/>
											Share
										</li>
										<li>
											<img src="/images/receive-icon.svg" alt="Download"/>
											Download
										</li>
										<li>
											<img src="/images/repeat-icon.svg" alt="Create"/>
											Create
										</li>
										<li>
											<img src="/images/heart-search-icon.svg" alt="Collect"/>
											Collect
										</li>
										<li>
											<img src="/images/trend-up-icon.svg" alt="Trend"/>
											Trend
										</li>
									</ul>
								</div>
							</div>
						</div>
					</section>
					<section className="about-how-it-works">
						<div className="container">
							<div className="about-how-it-works-content">
								<h2>How It Works</h2>
								<ul className="grid">
									<li>
										<img src="/images/trend-up-icon.svg" alt="Trend"/>
										<h3>See trending memes</h3>
										<p>No fear of missing out and the top conversations of the day? We have you covered with memes relating to the trending topics of the internet daily.</p>
									</li>
									<li>
										<img src="/images/heart-search-icon.svg" alt="Collect"/>
										<h3>Collect Memes</h3>
										<p>No need to search for those favourite memes every time you need them. We’ve created a collection of your favourite memes for you to access on your profile anytime you want to show not tell.</p>
									</li>
									<li>
										<img src="/images/send-icon.svg" alt="Share"/>
										<h3>Share Memes</h3>
										<p>Are you worried about clogging your phone memory with memes you may not get to use? You can simply share memes directly to any social media platform.</p>
									</li>
									<li>
										<img src="/images/repeat-icon.svg" alt="Create"/>
										<h3>Create memes</h3>
										<p>So you think you make jokes? Fine, you can create and publish your own memes here?</p>
									</li>
								</ul>
							</div>
						</div>
					</section>
					<section className="about-coming-soon">
						<div className="container">
							<div className="about-coming-soon-wrapper">
								<h2><span>Coming Soon</span> Native Keyboard</h2>
								<p>Download our smartphone keyboard to access th best memes right there in your chats.</p>
							</div>
						</div>
					</section>
					<section className="about-vp">
						<div className="container">
							<div className="grid about-vp-wrapper">
								<div className="about-vp-content">
									<h2>Become a <span>Creator</span></h2>
									<p>So you think you make jokes? Fine, you can create and publish your own memes here?</p>
									<Link to="/signup" className="btn btn-colored btn-full">Create an account</Link>
								</div>
								<div className="about-vp-newsletter">
									<h2>Join the Newsletter</h2>
									<p>So you think you make jokes? Fine, you can create and publish your own memes here?</p>
									<form className="form form-email" onSubmit={handleSubscribe}>
										<div className="form-email-main">
											<label>Email</label>
											<input onChange={handleInputChange} type="email" name='email' value={email} placeholder="Enter your email" required/>
										</div>
										<button className="form-submit btn btn-colored btn-full" disabled={subscribed}>
											{ subscribed ? 'Subscribed!' : 'Subscribe' }
										</button>
									</form>
								</div>
							</div>
						</div>
					</section>
				</main>
			</Layout>
		</>
	);
}

export default About;