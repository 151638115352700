import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const convertToSlug = (text) => {
	return text.toLowerCase()
		.replace(/ /g, '-')
		.replace(/[^\w-]+/g, '');
}

export const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

export const isEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

export const setToLocalStorage = (key, value, ttl) => {
	const now = new Date(),
		item = {
			value: value,
			expiry: now.getTime() + ttl,
		}

	localStorage.setItem(key, JSON.stringify(item))
}

export const getFromLocalStorage = (key) => {
	if (typeof localStorage === 'undefined') return null;

	const itemStr = localStorage.getItem(key);
	if (!itemStr) return null;

	const item = JSON.parse(itemStr),
		now = new Date();

	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null
	}

	return item.value
}

export const removeSpaces = (str) => str.replace(/\s/g, '');

export const getCookie = (cname) =>  {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}

	return "";
}

export const responsiveImages = (meme) => {
	if (!meme || !meme.imageSizes) return false;
	const sizes = meme.imageSizes;
	let srcset = '';

	for (let size in sizes) {
		let srcText = sizes[size].replace('meme-dev.', 'meme-dev-v2.');
		srcText = srcText.replace('/meme-dev/', '/meme-dev-v2/');

		srcset += `${srcText} ${size}w, `;
	}

	return srcset;
}

export const toQueryString = (object) => {
	return '?' + new URLSearchParams(object).toString();
}