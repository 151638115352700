import React from 'react';
import {AdSlot } from 'react-dfp';

const Ad = (props) => {
	// console.log(props.type === 'leaderboard');
	if (props.type === 'leaderboard') {
		return (
			<>
				<div className="ad container at-desktop">
					<AdSlot
						slotId={props.id}
						adUnit="ZKK_Top_Leaderboard_Desktop"
						sizes={[[728, 90]]}
					/>
				</div>
				<div className="ad container at-mobile">
					<AdSlot
						slotId={`${props.id}_MOBILE`}
						adUnit="ZKK_Top_Leaderboard_Desktop"
						sizes={[[320, 50]]}
					/>
				</div>
			</>
		)
	}

	return (
		<>
			<div className="ad mpu at-desktop">
				<AdSlot
					slotId={props.id}
					adUnit="ZKK_Sticky_DoubleMPU"
					sizes={[[300, 600]]}
				/>
			</div>
			<div className="ad mpu at-mobile">
				<AdSlot
					slotId={`${props.id}_MOBILE`}
					adUnit="ZKK_Sticky_DoubleMPU"
					sizes={[[300, 250]]}
				/>
			</div>
		</>
	)
}

export default Ad;