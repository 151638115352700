/**
 * Name: Page Layout component
 * Author: omeiza@bigcabal.com
 */

import * as React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

// Styles
import "../../styles/typography.scss";
import "../../styles/base.scss";
import "../../styles/grid.scss";
import "./Layout.scss";

const Layout = ({ children, title, updated }) => {
	let updatedDate = <div> Last Updated <span>{ updated }</span></div>;

	if (!updated?.length) {
		updatedDate = <></>;
	}

	React.useEffect(() => {
		document.body.style.overflow = "scroll";
	}, []);

	return (
		<>
			<div>
				<Header />
				<main className="page">
					<div className="container">
						<div className="grid grid-page-header page-header">
							<h1>{ title }</h1>
							{ updatedDate }
						</div>
					</div>
					<div className="container">
						{ children }
					</div>
				</main>
				<Footer />
			</div>
		</>
	)
}

export default Layout
