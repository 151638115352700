import { useState, useEffect, useContext } from "react";
import Context from '../context';
import axios from "axios";
import { getFromLocalStorage } from "../util/helpers";

export const useApiRequest = (url, { method = 'GET', transform = null, headers = {}, useAuth = false, initialValue = null, serverOnly = false } = {}) => {
    const { isServer, prefetch } = useContext(Context);
    const [ response, setResponse ] = useState(initialValue);
    const [ error, setError ] = useState(null);
    const [ isFound, setIsFound ] = useState(null);

    if (useAuth && !headers['x-api-key']) {
        headers['x-api-key'] = getFromLocalStorage("key");
    }
    if (serverOnly && !isServer) {
        url = null;
    }

    const makeRequest = () => axios({
        method,
        url,
        headers,
        // data,
        // responseType,
    });

    const retrievalKey = `${method}:${url}:${JSON.stringify(headers)}`;
    if (isServer && !prefetch?.[retrievalKey] && url) {
        prefetch[retrievalKey] = makeRequest().then(res => {
            const data = transform ? transform(res.data) : res.data;
            prefetch[retrievalKey] = [data, res.status < 400, null];
        }).catch(err => {
            prefetch[retrievalKey] = [null, false, err];
        });
    }

    useEffect(() => {
        if (!url) return;
        // console.log('Making API request', retrievalKey);
        makeRequest().then(res => {
            setResponse(res.data);
            setIsFound(res.status < 400);
        }).catch(err => {
            setError(err);
            setIsFound(false);
        });
    }, [retrievalKey]);
 
    if (isServer && prefetch[retrievalKey] && !(prefetch[retrievalKey] instanceof Promise)) {
        return prefetch[retrievalKey];
    }
    return [response, isFound, error ];
}