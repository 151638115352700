/**
 * Name: Menu component
 * Author: omeiza@bigcabal.com
 */

import { useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";
import { useAuth } from "../Auth/AuthProvider";

// Style
import "./Menu.scss";

const Menu = (props) => {
	const auth = useAuth(),
		navigate = useNavigate();

	useEffect(() => {
		document.body.style.overflow = "hidden";
	}, []);

	return (
		<div className="overlay">
			<div className="menu">
				<img onClick={props.closePopup} src="/images/close-icon.svg" className="menu-close" alt="Close Menu" />
				<section className="menu-info">
					<ul>
						<li><Link to="/about">About</Link></li>
						<li><Link to="/terms-of-service">Term of use</Link></li>
						<li><Link to="/community-guideline">Community Guidelines</Link></li>
						<li><a href="https://zikoko.com" target="_blank" rel="noreferrer">Zikoko.com <img src="/images/link-icon.svg" alt="Zikoko Link" /></a></li>
					</ul>
				</section>
				<section className="menu-auth">
					{
						auth.isLoggedIn() ?
							<ul>
								<li>
									<Link to={"/"} onClick={(e) => {
										e.preventDefault();
										auth.logout()
											.then(() => {
												navigate({ pathname: '/' });
											})
									}}>Log Out
									</Link>
								</li>
							</ul>
							:
							<ul>
								<li><Link to="/login">Log In</Link></li>
								<li><Link to="/signup">Sign Up</Link></li>
							</ul>
					}
				</section>
				<section className="menu-others">
					<ul>
						<li>© 2022 Z! Memes</li>
						<li><Link to="/privacy-policy">Privacy Policy</Link></li>
						<li><Link to="/copyright">Copyright</Link></li>
						<li><Link to="/manage-cookies">Manage Cookies</Link></li>
					</ul>
				</section>
			</div>
		</div>
	)
}

export default Menu;