import * as React from "react";
import PageLayout from "../../components/Layout/PageLayout";
import {Helmet} from "react-helmet";
import {Link, useLocation} from "react-router-dom";

const TermsOfService = () => {
	const location = useLocation();
	
	return (
		<>
			<Helmet>
				<title>Z! Memes Terms of Service | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="Z! Memes Terms of Service | Zikoko! Memes"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<PageLayout title="Z! Memes Terms of Service" updated="">
				<section className="grid grid-page-content page-content">
					<ul className="page-anchors">
						<li><a href="#introduction">Introduction</a></li>
						<li><a href="#age-restriction">Age Restriction</a></li>
						<li><a href="#creators-account">Creators Account</a></li>
						<li><a href="#your-content">Your Content</a></li>
						<li><a href="#proprietary-right">Proprietary Right</a></li>
						<li><a href="#copyright-issues">Copyright issues</a></li>
						<li><a href="#prohibited-content">Prohibited content</a></li>
						<li><a href="#termination">Termination</a></li>
						<li><a href="#link-third-party">Links to third party websites</a></li>
						<li><a href="#complaints">Complaints and Suggestions</a></li>
						<li><a href="#misc">Miscellaneous</a></li>
						<li><a href="#competitions">Competitions</a></li>
						<li><a href="#contact-us">Contact us</a></li>
					</ul>
					<div className="page-content-main info-content">
						<div id="introduction">
							<h2>Introduction</h2>
							<p>This platform is made available by Big Cabal Media (“Company”).</p>
							<p>Big Cabal Media (“us” or “our” or “we”) is a limited liability company, registered under the laws of the Federal Republic of Nigeria with its registered address at 18 Nnobi Street, Animashaun, Lagos, Nigeria.</p>
							<p>Read carefully through these Terms and Conditions which set forth the legally binding provisions of  browsing, accessing or using our website.</p>
							<p>We reserve the right to alter, modify, change, remove or add to any portion of these Terms and Conditions at any time without prior information. You are required to check these Terms and Conditions regularly for changes.</p>
							<p>By accessing our website, you have agreed to the Terms and Conditions set forth below. Our Terms and Conditions are as follows:</p>
						</div>
						<div id="age-restriction">
							<h2>Age Restriction</h2>
							<p>The platform should not be used by persons below the age of 16. By using the platform as a visitor or a creator, you are agreeing that you are above 16 years of age.</p>
						</div>
						<div id="creators-account">
							<h2>Creators Account</h2>
							<p>You may sign up as a creator to use the following features: Create Meme, Remix Meme, and Post comments. Given that you are signed up as a creator, you agree that;</p>
							<ol type="a">
								<li>You are not impersonating anyone by using a name belonging to or copyrighted to another body.</li>
								<li>You will not use offensive or inappropriate usernames, bios, or profile pictures.</li>
								<li>You will not post content you do not have appropriate authorization to publish.</li>
							</ol>
						</div>
						<div id="your-content">
							<h2>Your Content</h2>
							<p>As a creator, you are responsible and liable for all content published from your account (including but not restricted to memes, captions, profile pictures, usernames, bios, comments, etc)</p>
						</div>
						<div id="proprietary-right">
							<h2>Proprietary Right</h2>
							<p>By publishing any image or content on our site, you are agreeing that you have the proprietary rights over the content.</p>
						</div>
						<div id="copyright-issues">
							<h2>Copyright Issues</h2>
							<p>If you discover that content belonging to you is posted by other users on our platform, you may report the content and the admin will review and take down that content if it is found to be in violation of copyright.</p>
						</div>
						<div id="prohibited-content">
							<h2>Prohibited content</h2>
							<p>When using our platform, you must not submit, store, send, or share any content that fall under the following categories:</p>
							<ol type="a">
								<li>Adult/pornographic content</li>
								<li>Violence, mutilation and self-harm</li>
								<li>Spam posts</li>
								<li>Hate Speech and Harassment</li>
								<li>Fraudulent, unlawful, unauthorized and illegal content</li>
								<li>Private personal information</li>
								<li>Copyrighted content</li>
							</ol>
							<p>See more on prohibited content on Zikoko Memes’ <Link to="/community-guideline">community guidelines</Link>. Failure to adhere to these guidelines will result in massive deductions in your creators points, removal of posts/comments and possibly permanent deactivation of your account. These guidelines may also be updated regularly in a bid to keep the platform safe and avoid inappropriate content.</p>
						</div>
						<div id="termination">
							<h2>Termination</h2>
							<p>We can choose to terminate or suspend your account or use of the Services at any time. You can also choose to delete your account or stop use of the Services at any time, but note that certain provisions in this Terms and Condition will survive the deactivation of your account or end the use of our Services.</p>
						</div>
						<div id="link-third-party">
							<h2>Links to third party websites</h2>
							<p>Please be aware that our features, or Service may be connected to third party websites that are not controlled by us. However, we shall not be responsible for their content, privacy procedure, practice and/or security statement of any third party website, product or service. You agree that we shall not be directly or indirectly liable or responsible for any damage or loss caused or alleged to be caused by using or relying on any such product, service or content available on or through the use of the third party website, product or services. We strongly advise that you read carefully through the terms and conditions, privacy procedure and policies, and security statements of any third party websites that you have access to or visit.</p>
						</div>
						<div id="complaints">
							<h2>Complaints and Suggestions</h2>
							<p>If you wish to make a complaint or a suggestion regarding our website or any of our product or services we offer, please send an email to memes@bigcabal.com.</p>
						</div>
						<div id="misc">
							<h2>Miscellaneous</h2>
							<p>If any provision of these Terms and Conditions is declared by judicial authority  to be void, voidable, illegal or otherwise unenforceable, such provision shall be severed from these Terms and Conditions and the remaining provisions of these Terms and Conditions shall remain in effect and in full force.</p>
							<p>These Terms and Conditions constitute the entire agreement regarding our Services, and supersede as well as replace any prior agreements we might have had with you regarding our Services.</p>
							<p>Where you disagree with any alteration, modifications, changes or variation in our Terms and Conditions, you should immediately cease from using our Services.</p>
						</div>
						<div id="complaints">
							<h2>Competitions</h2>
							<ol type="a">
								<li>
									<h3>Eligibility:</h3>
									<ol type="a">
										<li>Unless stated otherwise, the sponsor of any competition on the site is Big Cabal Media.</li>
										<li>Each winner may not win more than one prize per competition.</li>
										<li>Participants who use fake accounts to participate in the competition will be disqualified from further participation, at the discretion of the Sponsor.</li>
										<li>Big Cabal Media reserves the right to term a share in the competition fake, at their discretion, and the right to disqualify the participant without notice.</li>
										<li>Participants may not enter the competition multiple times, by using different email  identities, or devices to improve their chances of winning.</li>
										<li>Participants in the competition are not at liberty to choose their own prizes, and can only be awarded the rewards set forth by the sponsor.</li>
										<li>Participants will be notified of their status within 14 days of winning a prize.</li>
										<li>The Sponsor shall have no liability for a participant’s failure to receive notices of their prize due to spam, offline status, or for a participant’s provision of incorrect or otherwise non-functioning contact information.</li>
										<li>If the participant cannot be contacted, is found ineligible, or fails to provide delivery information within 7 days from the time award notification was sent, the prize may be forfeited, and an alternate Winner selected.</li>
										<li>The Zikoko Memes competitions are only open to participants who are 18 years and older, and reside in Nigeria</li>
										<li>At this time, no prizes are shipped to competition participants outside the following countries; Nigeria.</li>
									</ol>
								</li>
								<li>
									<h3>Rights Granted By The Entrant:</h3>
									<ol type="a">
										<li>By entering this competition, you understand and agree (or your parent or legal guardian understands and agrees, if you are a minor in your country of residence), that the sponsor and anyone acting on behalf of the sponsor or its respective licensees, successors and assigns, shall have the right, where permitted by law, to print, publish, broadcast, distribute, and use in any media now known or hereafter developed, in perpetuity and throughout the world, without limitation, your entry, name, portrait, picture, voice, likeness, image, statements about the rewards, and biographical information for news, publicity, information, trade, advertising, public relations, and promotional purposes without any further compensation, notice, review or consent.</li>
										<li>By entering the competition, the participant (“you”) agrees to comply with and abide by the rules and decisions of the sponsor, and you warrant that you meet the eligibility requirements. In addition, you agree to accept the sponsor’s decision as final and binding as it relates to any Zikoko memes competition.</li>
										<li>Participants should look to the sponsor with any questions, comments, or issues related to any Zikoko memes competition.</li>
									</ol>
								</li>
								<li>
									<h3>Terms:</h3>
									<ol type="a">
										<li>The sponsor reserves the right, in its sole discretion, to cancel, terminate, modify or suspend the competition should virus, bug, non-authorized human intervention, fraud, or other cause beyond the Sponsor’s control corrupt or affect the administration, security, fairness, or proper conduct of the referral rewards program.</li>
										<li>The sponsor reserves the right at its sole discretion to disqualify any individual who tampers or attempts to tamper with the entry process or the operation of the competition or website or violates these Terms & Conditions.</li>
										<li>The sponsor has the right, in its sole discretion, to maintain the integrity of the competition, to void entries for any reason, including, but not limited to: multiple entries from the same user from different IP addresses; multiple entries from the same computer in excess of that allowed by competition rules; or the use of bots, macros, scripts, or other technical means for entering.</li>
										<li>Any attempt by an entrant to deliberately damage any website or undermine the legitimate operation of the competition may be a violation of criminal and civil laws. Should such an attempt be made, the sponsor reserves the right to seek damages to the fullest extent permitted by law.</li>
									</ol>
								</li>
								<li>
									<h3>Limitation of Liability:</h3>
									<p>By entering the a competition on the site, you agree to release and hold harmless sponsor and its subsidiaries, affiliates, advertising and promotion agencies, partners, representatives, agents, successors, assigns, employees, officers and directors from any liability, illness, injury, death, loss, litigation, claim or damage that may occur, directly or indirectly, whether caused by negligence or not, from (i) such entrant’s participation in the referral rewards program and/or his/her acceptance, possession, use, or misuse of any prize or any portion thereof; (ii) unauthorized human intervention in any part of the referral rewards program; (iii) electronic or human error in the administration of the referral rewards program or the processing of entries; (iv) technical errors of any kind, including but not limited to the malfunction of any computer, cable, network, hardware, or software, or other mechanical equipment; (v) the unavailability or inaccessibility of any transmissions, telephone, or Internet service; (vi) printing errors; (vii) lost, late, postage due, misdirected, or undeliverable mail.</p>
								</li>
								<li>
									<h3>Disputes:</h3>
									<ol type="a">
										<li>This competition is governed by the laws of Nigeria, without respect to conflict of law doctrines.</li>
										<li>By participating in this competition, you agree that any and all disputes that cannot be resolved between the parties, and causes of action arising out of or connected with this referral rewards program, shall be resolved individually, without resort to any form of class action, exclusively before a court located in the state or federal courts located in Nigeria, and each participant accepts and submits to the personal jurisdiction of such courts with respect to any legal actions, suits or proceedings arising out of or related to the competition.</li>
										<li>Further, in any such dispute, under no circumstances shall You be permitted to obtain awards for, and hereby waive all rights to, punitive, incidental, or consequential damages, including reasonable attorney’s fees, other than actual out-of-pocket expenses (i.e. costs associated with entering the referral program). You further waive all rights to have damages multiplied or increased.</li>
									</ol>
								</li>
								<li>
									<h3>Force Majeure:</h3>
									<ol type="a">
										<li>Without limiting any other provision in these Official Rules, in the event that any of the competition activities or the sponsor’s operations or activities are affected, as determined by the sponsor, in its sole discretion, including, without limitation, by reason of any acts of God, any action, regulation, order or request by any governmental or quasi-governmental entity (whether or not the action, regulations, order or request proves to be invalid), equipment failure, threatened terrorist acts, terrorist acts, air raid, blackout, act of public enemy, earthquake, war (declared or undeclared), fire, flood, pandemic, epidemic or public health crisis, explosion, unusually severe weather, hurricane, embargo, labor dispute or strike (whether legal or illegal) labor or material shortage, transportation interruption of any kind, work slow-down, civil disturbance, insurrection, riot, or any law, rule, regulation, order or other action adopted or taken by any governmental, federal, state or local government authority, or any other cause, whether or not specifically mentioned above, subject to any governmental approval that may be required, the Sponsor shall have the right to modify, suspend, or terminate the competition, and no releasees shall be responsible or liable to any entrant or prize winner or any person claiming through such entrant or prize winner for failure to supply the prize or any part thereof.</li>
										<li>The sponsor is not responsible for electronic communications that are undeliverable as a result of any form of active or passive filtering of any kind, or offline status, or insufficient space in a participant's device to receive messages.</li>
									</ol>
								</li>
							</ol>
						</div>
						<div id="contact-us">
							<h2>Contact Us</h2>
							<p>If you have any questions regarding our Services, you can email us at support.</p>
						</div>
					</div>
				</section>
			</PageLayout>
		</>
	);
}

export default TermsOfService;