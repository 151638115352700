import React, { useState, useEffect } from "react";
import axios from "axios";
import {useLocation, useParams} from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Search from "../components/Search/Search";
import ReportMeme from "../components/Meme/ReportMeme";
import ShareMeme from "../components/Meme/ShareMeme";
import { Link } from "react-router-dom";
import {convertToSlug, getFromLocalStorage, responsiveImages, toQueryString} from "../util/helpers";
import fileDownload from 'js-file-download';
import { ListSection, ArticleHandler } from '../components/ListSection';
import { useAuth } from "../components/Auth/AuthProvider";
import ReactGA from 'react-ga4';
import NotFound from '../routes/pages/NotFound';
import { useApiRequest } from "../lib/api";
import AdContext from "../components/Ad/AdContext";
import Ad from "../components/Ad";
import {Helmet} from "react-helmet";
import { useNavigate } from "react-router-dom";

const Modal = {
	Report: 1,
	Share: 2,
};

function MemeProfile({meme, isLiked}) {
	const location = useLocation();
	const auth = useAuth();
	const [modal, setModal] = useState(null);
	const [liked, setLiked] = useState(isLiked);
	const [viewCount, setViewCount] = useState(0);
	const [downloading, setDownloading] =  useState(false);

	const doLikeMeme = () => {
		const headerObject = {};
		if (getFromLocalStorage("key")) {
			headerObject.headers = { "x-api-key": getFromLocalStorage("key") }
		}
	
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/memes/${meme?.id}/like`, {}, headerObject)
			.then((res) => {
				if (res.data) {
					setLiked(res.data.liked);
					ReactGA.event({
						category: 'meme',
						action: res.data.liked ? 'like' : 'unlike',
						value: meme.id,
					});
				}
			})
			.catch((err) => {
				console.log(err);
		});
	}

	const doDownloadMeme = () => {
		setDownloading(!downloading);
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/memes/${meme?.id}/download`, {
			responseType: 'blob',
		}).then((res) => {
			if (res.data) {
				const linkArray = meme?.url.split("/");
				if (linkArray) fileDownload(res.data, linkArray[linkArray.length - 1]);
				setDownloading(false);
				ReactGA.event({
					category: 'meme',
					action: 'download',
					value: meme.id,
				});
			}
		});
	}

	useEffect(() => {
		if (!modal) {
			document.body.style.overflow = "scroll";
			return;
		}
	}, [ modal ]);

	useEffect(() => {
		if (!meme) return;

		// Get view count from API/Redis
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/memes/${meme.id}/viewCount`)
			.then(result => {
				setViewCount(result?.data.count);
			})
			.catch(err => {
				console.log("HyperLogLogs retrieved count error", err);
			});

		// Get view count from API/Redis
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/memes/${meme.id}/viewCount`)
			.then(() => {})
			.catch(err => {
				console.log("HyperLogLogs add count error", err);
			})
	}, [meme]);

	// We have a caption
	const description = meme?.tags?.join(' ') ?? "Another meme from Zikoko! Memes";
	const articleStructuredData = {
		"@context": "https://schema.org/",
		"@type": "Article",
		"mainEntityOfPage": {
			"@type": "WebPage",
			"@id": process.env.REACT_APP_BASE_URL + location.pathname
		},
		"headline": meme?.title,
		"name": "Zikoko Memes",
		"description": description,
		"image": [
			meme?.url
		]
	}

	const imgSrcSet = responsiveImages(meme);
	let imgHTML = <img className="meme-image-main" src={meme?.url} alt={meme?.title} />;
	if (imgSrcSet) imgHTML = <img className="meme-image-main" onError={(e) => { e.target.removeAttribute('srcset') }} src={meme?.url} srcSet={`${imgSrcSet}`} alt={meme?.title} />;
	return (
		<>
			<Helmet>
				<title>{`${meme?.title} | Zikoko! Memes`}</title>
				<meta property="description" content={description}/>
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content={`${meme?.title} | Zikoko! Memes`}/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={meme?.url ?? `${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content={description}/>
				<meta property="og:image:width" content={meme?.width}/>
				<meta property="og:image:height" content={meme?.height}/>
				<meta property="twitter:image" content={meme?.url ?? `${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`}/>
				<script type="application/ld+json">{JSON.stringify(articleStructuredData)}</script>
			</Helmet>
			<section className="meme">
				<div className="container">
					<div className="grid grid-meme">
						<Ad id="BESIDE-SINGLE-MEME" type="mpu" />
						<div className="meme-main">
							<div className="meme-heading">
								<h1>{meme?.title ?? "\u00A0"}</h1>
								<div className="meme-count">
									{ viewCount <= 1 ? `${viewCount} View` : `${viewCount} Views` }
									<img src="/images/views-icon.svg" alt="Meme count"/>
								</div>
							</div>
							<div className="grid grid-image-source">
								<div className="meme-contributor user-profile">
									<img src={ meme?.contributor?.image ?? "/images/profile-circle.svg" } alt={`${meme?.contributor.username ?? "\u00A0"}`} width="20" height="20"/>
									<Link to={`/profile/${meme?.contributor.username}`}>{`@${meme?.contributor.username ?? "\u00A0"}`}</Link>
								</div>
								<p onClick={
									() => {
										navigator.clipboard.writeText(meme?.source);
										alert(`${meme?.source} copied to clipboard!`);
									}
								}>{meme?.source}</p>
							</div>
							<div className="meme-image">
								{ meme ?
									imgHTML
									:
									<div className="meme-image-main">Loading...</div>
								}
								<img onClick={doLikeMeme} className="meme-image-like" src={ liked ? "/images/heart-filled.svg" : "/images/heart.svg" } alt="Like Meme" />
								<div className="meme-actions">
									<ul>
										<li className={downloading ? 'inactive' : ''}>
											<span onClick={downloading ? null : doDownloadMeme}>
												<img src="/images/download-icon.svg" alt="Download Meme" />
												{
													downloading ?
														'Downloading'
														:
														'Download'
												}
											</span>
										</li>
										{
											auth?.user ?
												meme?.mimeType !== "image/gif" ?
													<li>
														<Link to={`/remix/${meme?.id}`}>
															<img src="/images/remix-icon.svg" alt="Remix Meme" />
															Remix
														</Link>
													</li>
													:
													null
												:
												<li>
													<Link to={`/login${toQueryString({'redirect': `/remix/${meme?.id}`})}`}>
														<img src="/images/remix-icon.svg" alt="Remix Meme" />
														Remix
													</Link>
												</li>
										}
										<li>
											<span onClick={() => setModal(Modal.Share)}>
												<img src="/images/share-icon.svg" alt="Share Meme" />
												Share
											</span>
										</li>
										<li>
											<span onClick={() => setModal(Modal.Report)}>
												<img src="/images/report-icon.svg" alt="Report Meme" />
												Report
											</span>
										</li>
										{
											auth?.user && auth?.user.id === meme?.contributor.id ?
												<li>
													<Link to={`/edit/${meme?.id}`}>
														<img src="/images/svg-icon-edit-cropped.svg" alt="Edit Meme" />
														Edit
													</Link>
												</li>
												:
												null
										}
									</ul>
								</div>
							</div>
							<div className="meme-tag-cloud">
								{ meme?.tags?.map(tag => (
									<Link to={`/search?q=${encodeURIComponent(tag.trim())}`} key={tag}>{`#${tag.trim()}`}</Link>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
			{ modal === Modal.Report ? <ReportMeme id={meme?.id} closePopup={() => setModal(null)} /> : null }
			{ modal === Modal.Share ? <ShareMeme meme={meme} closePopup={() => setModal(null)} /> : null }
		</>
	);
}

const MemeRoute = () => {
	const params = useParams();
	const slug = params.slug;
	const auth = useAuth();
	let slugArray, id, isLiked;

	if (slug) {
		slugArray = slug.split("-");
		id = parseInt(slugArray[slugArray.length - 1]);
		if (auth?.user?.likes?.find(x => x.memeId === id)) {
			isLiked = true;
		}
	}

	const getRelatedUrl = () => {
		if (!meme) return;
		let zEndpoint = 'https://www.zikoko.com/wp-json/wp/v2/search?subtype=post&_embed=1';
		// let allEndpoint = zEndpoint;
		
		for (const tag of meme.tags) {
			zEndpoint = zEndpoint + '&search=' + encodeURIComponent(tag.trim().toLowerCase());
			break;
		}
		return zEndpoint;
	};

	const [meme, isFound] = useApiRequest(`${process.env.REACT_APP_API_BASE_URL}/memes/${id}`, { useAuth: true });
	const [relatedUrl, setRelatedUrl] = useState(meme ? getRelatedUrl() : null);
	const [related] = useApiRequest(relatedUrl);

	useEffect(() => {
		const url = getRelatedUrl();
		if (url) setRelatedUrl(url);
	}, [meme]);

	if (isFound === false) return (<NotFound/>);
	return (
		<Layout>
			<AdContext>
				<Search />
				<main>
					<MemeProfile key={meme?.id} meme={meme} isLiked={isLiked}/>
					{ related && related.length > 0 ?
						<>
							<ListSection
								pageClassName="zikoko-articles" title="Related Z! Articles" showScrollers={true}
								items={related} ItemHandler={ArticleHandler} columns="4" mobileColumns="1" scroller={true} className="zikoko-articles"
							/>
							<Ad id="AFTER_ZIKOKO_RELATED" type="leaderboard" />
						</>
					: null }
					{ meme?.related?.length > 0 ?
						<>
							<ListSection
								pageClassName="related-memes" title="Related Memes"
								columns="4" mobileColumns="2" items={meme.related} count="20"
							/>
							<Ad id="BEFORE_FOOTER" type="leaderboard" />
						</>
					 : null }
				</main>
			</AdContext>
		</Layout>
	);
}

export default MemeRoute;
