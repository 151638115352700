import React, {useEffect, useState, useRef} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {setToLocalStorage} from "../../util/helpers";
import axios from "axios";

const UploadPopup = (props) => {
	const uploadClick = useRef(null);
	const location = useLocation();
	const navigate = useNavigate();
	const [imagePreview, setImagePreview] = useState(null);
	const [signedData, setSignedData] = useState(null);
	const [uploadFile, setUploadFile] = useState(null);

	useEffect(() => {
		document.body.style.overflow = "hidden";

		function getSignedURL() {
			if (uploadFile) {
				const data = {
					"filename": uploadFile.name,
					"mimeType" : uploadFile.type
				}

				axios.post(`${process.env.REACT_APP_API_BASE_URL}/public/uploadUrl`, data, {
					headers: {
						'Content-Type': 'application/json'
					}
				})
					.then ((res) => {
						setSignedData(res.data);
					})
					.catch ((error) => {
						console.log(error);
					})
			}
		}

		if (uploadFile) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImagePreview(reader.result);
				getSignedURL();
			};

			reader.readAsDataURL(uploadFile);
		}
	}, [uploadFile]);

	useEffect(() => {
		if (!signedData || !uploadFile) return;

		const formData = new FormData();
		const origin = new URL(document.location.href);

		origin.pathname = '';
		origin.search = '';

		formData.append("Content-Type", uploadFile.type);
		Object.entries(signedData.uploadData.fields).forEach(([k, v]) => {
			return formData.append(k, v);
		});

		formData.append("file", uploadFile);
		axios.post(signedData.uploadData.url, formData, {})
			.then ((res) => {
				setToLocalStorage("uploadUrl", signedData.getUrl, 60 * 60 * 1000);
			})
			.then(() => {
				if (location.pathname === "/upload-meme/" || location.pathname === "/upload-meme") {
					window.location.reload();
				} else {
					navigate({ pathname: '/upload-meme' });
				}
			})
			.catch ((error) => {
				console.log(error);
			});
	}, [signedData, uploadFile]);

	function handleImageSelection (event) {
		const files = event.currentTarget.files;

		if (files) {
			setUploadFile(files[0]);
		}
	}

	return (
		<div className="overlay">
			<div className="meme-uploader">
				<div className="meme-report-heading">
					<h2>Upload Meme</h2>
					<img onClick={props.closeUploadPopup} src="/images/close-icon.svg" className="menu-close" alt="Close Menu" />
				</div>
				<div className="meme-report-details meme-report-submitted">
					{ imagePreview ? (
						<div className="meme-uploader-wrapper" style={{backgroundImage: `url("${imagePreview}")`, backgroundSize: 'cover'}}>
							<button className="meme-uploader-button" type="button" disabled>
								Uploading...
							</button>
						</div>
					) : (
						<div className="meme-uploader-wrapper">
							<button className="meme-uploader-button" type="button" 
								onClick={() => { uploadClick.current?.click(); }}>Upload from device</button>
							<input 
								id="file" name="file" type="file" 
								accept="image/png, image/jpeg, image/gif"
								ref={uploadClick} onChange={handleImageSelection} />
						</div>			
					) }
				</div>
			</div>
		</div>
	)
}

export default UploadPopup;