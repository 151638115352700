import * as React from "react";
import Layout from "../components/Layout/Layout";
import Search from "../components/Search/Search";
import HotSingle from "../components/Hot/HotSingle";

// To fix
const Topic = () => {
	return (
		<Layout>
			<>
				<Search />
				<main>
					<HotSingle data={[]} />
				</main>
			</>
		</Layout>
	);
}

export default Topic;
