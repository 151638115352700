import {useMemo} from "react";
import EmailForm from "../../components/Auth/EmailForm";
import {useLocation} from "react-router-dom";
import {isEmail} from "../../util/helpers";
import SignupForm from "../../components/Auth/SignupForm";
import {Helmet} from "react-helmet";

const Signup = () => {
	let query = useQuery();
	const email  = query.get("email");
	const location = useLocation();

	if (email && isEmail(email)) {
		return (
			<main className="auth">
				<div className="container container-auth">
					<SignupForm email={email} step={2} />
				</div>
			</main>
		)
	}
	if (email) {
		console.log("Invalid email", email);
	}

	function useQuery() {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	return (
		<>
			<Helmet>
				<title>Sign up | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="Zikoko! Memes | There's a meme for that and Zikoko! has it"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<main className="auth">
				<div className="container container-auth">
					<EmailForm />
				</div>
			</main>
		</>
	);
}

export default Signup;