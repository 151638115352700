import * as React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Styles
import "../../styles/forms.scss";

const EmailForm = ({redirect}) => {
	const googleLogin = () => {
		window.open(`${process.env.REACT_APP_API_BASE_URL}/auth/google`, "_self");
	};

	const twitterLogin = () => {
		window.open(`${process.env.REACT_APP_API_BASE_URL}/auth/twitter`, "_self");
	};

	const facebookLogin = () => {
		window.open(`${process.env.REACT_APP_API_BASE_URL}/auth/facebook`, "_self");
	};

	const initialState = { email: "", password: "", isSubmitting: false, errorMessage: "" },
		[data, setData] = React.useState(initialState),
		[userExist, setUserExist] = React.useState(false),
		[formStep, setFormStep] = React.useState(1),
		navigate = useNavigate();

	const handleInputChange = (event)=> {
		const target = event.target;
		setData({
			...data,
			email: target.value
		});
	};

	const handleEmailCheck = (event) => {
		event.preventDefault();
		setData({
			...data,
			isSubmitting: true,
			errorMessage: ""
		});

		axios.post(`${process.env.REACT_APP_API_BASE_URL}/checkEmail`, {
			email: data.email,
		})
			.then(function (response) {
				setUserExist(true);
				setFormStep(2);
			})
			.catch((err) => {
				if (err.response) {
					if (err.response.status === 404) {
						setFormStep(2);
					}
				}
			});
	};

	React.useEffect(() => {
		if (formStep === 2 && !userExist) {
			navigate({
				pathname: '/signup',
				search: '?email=' + encodeURIComponent(data.email),
			});
		}
	
		if (formStep === 2 && userExist) {
			if (redirect) {
				navigate({
					pathname: '/login',
					search: `?email=${encodeURIComponent(data.email)}&redirect=${redirect}`,
				});
			} else {
				navigate({
					pathname: '/login',
					search: '?email=' + encodeURIComponent(data.email),
				});
			}
		}	
	}, [data.email, formStep, navigate, userExist]);

	return (
		<div className="form form-email">
			<h2>Memes for <span>every mood</span></h2>
			<div className="form-social">
				<p>Sign in with socials</p>
				<ul className="form-social-wrapper">
					<li onClick={googleLogin}><img src="/images/google-social-colored.svg" alt="Google Logo" /></li>
					<li onClick={facebookLogin}><img src="/images/facebook-social-colored.svg" alt="Facebook Logo" /></li>
					<li onClick={twitterLogin}><img src="/images/twitter-social-colored.svg" alt="Twitter Logo" /></li>
				</ul>
			</div>
			<div>
				<p>Or continue with email</p>
				<form onSubmit={handleEmailCheck}>
					<div className="form-email-main">
						<label>Email</label>
						<input autoFocus onChange={handleInputChange} name="email" type="email" placeholder="Enter your email" required/>
					</div>
					<button className="form-submit btn btn-colored btn-full" disabled={data.isSubmitting}>
						{data.isSubmitting ? (
							"Loading..."
						) : (
							"Continue"
						)}
					</button>
				</form>
			</div>
		</div>
	);
}

export default EmailForm;