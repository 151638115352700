import {useMemo, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

// Styles
import "../../styles/forms.scss";
import axios from "axios";

const ResetPasswordForm = () => {
	const initialState = { email: "", isSubmitting: false, errorMessage: "" };
	const [data, setData] = useState(initialState);
	const query = useQuery();
	const [passwordShown, setPasswordShown] = useState(false);
	const resetKey = query.get("key");
	const [passwordReset, setPasswordReset] = useState(false);
	const navigate = useNavigate();

	function useQuery() {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	const handleInputChange = (event)=> {
		setData({
			...data,
			[event.target.name]: event.target.value
		});
	};

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	function handlePasswordReset(event) {
		event.preventDefault();

		// Check for password confirm
		if (data.password !== data.confirmPassword) {
			setData({
				...data,
				errorMessage: "Passwords don't match"
			});

			return;
		}

		setData({
			...data,
			isSubmitting: true,
			errorMessage: ""
		});

		if (resetKey) {
			axios.post(`${process.env.REACT_APP_API_BASE_URL}/resetPassword/${resetKey}`, {
				newPassword: data.password

			})
				.then(function () {
					setPasswordReset(!passwordReset);
					setData({
						...data,
						isSubmitting: false
					});
				})
				.catch((err) => {
					if (err.response) {
						setData({
							...data,
							isSubmitting: false,
							errorMessage: "Unknown user"
						});
					}
				});
		} else {
			setData({
				...data,
				isSubmitting: false,
				errorMessage: "Unknown user"
			});
		}
	}

	return (
		<>
			{
				passwordReset ?
					<section className="verify-email">
						<div className="container">
							Password successfully changed. <Link to="/login">Login</Link>
						</div>
					</section>
					:
					null
			}
			<main className="auth">
				<div className="container container-auth">
					<div className="form form-email form-forgot-password">
						<img className="back-to-previous" onClick={() => navigate(-1)} src="/images/arrow-back.svg" alt="Go back"/>
						<h2>Create New Password</h2>
						{
							!data.errorMessage || data.errorMessage === "" ?
								null
								:
								<div className="form-error-message">
									{data.errorMessage}
								</div>
						}
						<form onSubmit={handlePasswordReset}>
							<div className="form-password-main">
								<label>Password</label>
								<input autoFocus onChange={handleInputChange} name="password" type={passwordShown ? "text" : "password"} placeholder="Enter your password" required/>
								<div onClick={togglePassword} className="show-password">
									<img src="/images/password-show.svg" alt="Show password" />
								</div>
							</div>
							<div className="form-password-main">
								<label>Password</label>
								<input autoFocus onChange={handleInputChange} name="confirmPassword" type="password" placeholder="Enter your password" required/>
							</div>
							<button className="form-submit btn btn-colored btn-full btn-disabled" disabled={data.isSubmitting}>Change Password</button>
						</form>
					</div>
				</div>
			</main>
		</>
	);
}

export default ResetPasswordForm;