import { useState, useEffect } from "react";
import Context from './context';
import * as Constants from './lib/constants';

import ReactGA from 'react-ga4';

import React from "react";
import {AuthProvider} from "./components/Auth/AuthProvider";
import AppRoutes from './AppRoutes';

ReactGA.initialize('G-GR7W85EREN');
ReactGA.send('pageview');


function App({ isServer = false, prefetch = null }) {
    const [currentCountry, setCurrentCountry] = useState('ng'); // Persist TODO

    useEffect(() => {
		// TODO: Detect country
		// update setCurrentCountry if not set
	}, []);

	return (
		<Context.Provider value={{
			supportedCountries : Constants.SUPPORTED_COUNTRIES,
			currentCountry, setCurrentCountry,
			isServer, prefetch
		}}>
            <AuthProvider isServer={isServer}>
                <AppRoutes/>
            </AuthProvider>
		</Context.Provider>
	);
}

export default App;