export const SUPPORTED_COUNTRIES = {
    ng: {
        name: 'Nigeria',
    },
    gh: {
        name: 'Ghana',
    },
    ke: {
        name: 'Kenya',
    },
    za: {
        name: 'South Africa',
    }
};

export const MEDIA_FILTERS = {
    All: 1,
    GIFs: 2,
    Images: 3,
}