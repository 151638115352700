import * as React from "react";
import ForgotPasswordForm from "../../components/Auth/ForgotPasswordForm";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const ForgotPassword = () => {
	const location = useLocation();

	return (
		<>
			<Helmet>
				<title>Forgot password | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="Zikoko! Memes | There's a meme for that and Zikoko! has it"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<main className="auth">
				<div className="container container-auth">
					<ForgotPasswordForm />
				</div>
			</main>
		</>
	);
}

export default ForgotPassword;