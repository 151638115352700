/**
 * Name: Profile component
 * Description: Profile section
 * Author: omeiza@bigcabal.com
 */
import {Link} from "react-router-dom";
import {useAuth} from "../Auth/AuthProvider";
import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ListSection } from "../ListSection";

// Styles
import "./User.scss";

// Types
const Profile = ({ username }) => {
	const [profile, setProfile] = useState(null);
	const auth = useAuth();
	const navigate = useNavigate();


	useEffect(() => {
		const url = `${process.env.REACT_APP_API_BASE_URL}/users/${encodeURIComponent(username)}`;
	
		axios.get(url)
			.then(result => {
				setProfile(result.data);
			}).catch(() => {
				setProfile(new Error("Not found"));
			});		
	}, [username]);

	if (!profile)  return null;
	if (profile instanceof Error) navigate('/');

	return (
		<section className="profile">
			<div className="container">
				<div className="grid profile-wrapper">
					<div className="profile-info">
						<div className="grid heading-section">
							<h2 className="heading-title">
								Profile
							</h2>
							<div className="profile-info-leaderboard">
								<Link to="/leaderboard">Leaderboard <img src="/images/ranking-icon.svg" alt="Leaderboard link" /></Link>
							</div>
						</div>
						<div className="profile-info-main">
							{
								profile.image ?
									<div className="profile-info-img" style={{backgroundImage: `url(${profile.image})`}} />
									:
									<div className="profile-info-img">
										<img src="/images/profile-circle.svg" alt="Profile" />
									</div>
							}
							<p className="profile-info-username">{`@${profile.username}`}</p>
							<ul className="profile-info-metrics">
								<li><span>{ profile.memeCount }</span> Memes</li>
								<li><span>{profile.points}</span> Points</li>
							</ul>
							{
								profile.bio ?
									<p className="profile-info-description">{profile.bio}</p>
									:
									null
							}
							{
								auth.isLoggedIn() && auth.user.isActive && profile.id === auth.user?.id ?
									<Link className="profile-info-edit btn btn-full btn-colored" to="/profile">Edit Profile</Link>
									:
									null
							}
						</div>
					</div>
					<ListSection 
						tabs={{
							Favourites: {
								endPoint: '/users/' + encodeURIComponent(username) + '/favourites',
								args: {},
								notFound: <p style={{textAlign: 'center', fontSize: 'large'}}>This user has no favourites.<br/><br/>All memes are created equal to them</p>,
							},
							Memes: {
								endPoint: '/memes',
								args: { username },
								notFound: <p style={{textAlign: 'center', fontSize: 'large'}}>This user is keeping their memes to themselves</p>,
							},
							_default: 'Memes',
						}} 
						columns={4} mobileColumns={2} autoload={true} masonry={true} authorise={true}/>
				</div>
			</div>
		</section>
	)
}

export default Profile;