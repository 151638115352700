/**
 * Name: Hot Topics component
 * Description: Hot Topics section
 * Author: omeiza@bigcabal.com
 */

// Styles
import "./Hot.scss";
import * as React from "react";
import GridListing from "../GridListing";

const Section = ({className, title, children}) => {
	return (
		<section className={className}>
			<div className="container">
				<div className="heading-section">
					<h2 className="heading-title top-heading-select">
						{ title instanceof Function ? title() : title }
					</h2>
				</div>
				{children}
			</div>
		</section>
	);
}

const HotAll = ({ count = 24 } = {}) => {
	const TopicItem = ({ item }) => {
		return (
			<a href={`/search?type=All&q=${encodeURIComponent(item.tags.join(' '))}`}>
				<div className="thumb"><img src={item.image} alt={item.name} /></div>
				<div className="label">{item.name}</div>
			</a>
		);
	};

	return (
		<Section className="top-all" title="Hot Topics" moreLink="/topics">
			<GridListing endPoint="/memes/topics" ItemHandler={TopicItem} columns="4" className="label-overlay" count={count} placeholderCount={12}/>
		</Section>
	)
}

export default HotAll;