import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EmailForm from "./EmailForm";
import { useAuth } from "./AuthProvider";
import {setToLocalStorage} from "../../util/helpers";

// Styles
import "../../styles/forms.scss";

const LoginForm = ({step, email, redirect}) => {
	const initialState = { email: "", password: "", isSubmitting: false, errorMessage: ""},
		[data, setData] = useState(initialState),
		[passwordShown, setPasswordShown] = useState(false),
		navigate = useNavigate(),
		auth = useAuth();

	const handleInputChange = (event)=> {
			setData({
				...data,
				email: email,
				[event.target.name]: event.target.value
			});
		};

	const handleLogin = (event) => {
		event.preventDefault();

		setData({
			...data,
			isSubmitting: true,
			errorMessage: ""
		});

		auth.login(data.email, data.password)
			.then(function (response) {
				if (response.data && response.status === 200) {
					setToLocalStorage("key", response.data.apikey, 7 * 24 * 60 * 60 * 1000);
				}

				if (redirect) {
					navigate({ pathname: redirect });
				} else {
					navigate({ pathname: '/' });
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					setData({
						...data,
						isSubmitting: false,
						errorMessage: "Incorrect password"
					});
				} else {
					setData({
						...data,
						isSubmitting: false,
						errorMessage: "Error logging in"
					});
				}
			});
	};

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	if (step === 1) {
		return (
			<EmailForm />
		)
	}

	return (
		<div className="form form-email">
			<img className="back-to-previous" onClick={() => navigate(-1)} src="/images/arrow-back.svg" alt="Go back"/>
			<h2>Memes for <span>every mood</span></h2>
			<div>
				<p>Log in with email</p>
				{
					!data.errorMessage || data.errorMessage === "" ?
						null
						:
						<div className="form-error-message">
							{data.errorMessage}
						</div>
				}
				<form onSubmit={handleLogin}>
					<div className="form-email-main">
						<label>Email</label>
						<input readOnly name="email" type="email" value={email ? email : ""} disabled={!!email} placeholder="Enter your email" required/>
					</div>
					<div className="form-password-main">
						<label>Password</label>
						<input autoFocus onChange={handleInputChange} name="password" type={passwordShown ? "text" : "password"} placeholder="Enter your password" required/>
						<div onClick={togglePassword} className="show-password">
							<img src="/images/password-show.svg" alt="Show password" />
						</div>
					</div>
					<p className="forgot-password-link">
						<Link to="/forgot-password">Forgot Password</Link>
					</p>
					<button className="form-submit btn btn-colored btn-full" disabled={data.isSubmitting}>
						{data.isSubmitting ? (
							"Logging in..."
						) : (
							"Log in"
						)}
					</button>
				</form>
			</div>
		</div>
	);
}

export default LoginForm;