import * as React from "react";
import PageLayout from "../../components/Layout/PageLayout";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const PrivacyPolicy = () => {
	const location = useLocation();

	return (
		<>
			<Helmet>
				<title>Z! Memes Privacy Policy | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="Z! Memes Privacy Policy | Zikoko! Memes"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<PageLayout title="Z! Memes Privacy Policy" updated="July 1, 2021">
				<section className="grid grid-page-content page-content">
					<ul className="page-anchors">
						<li><a href="#introduction">Introduction</a></li>
						<li><a href="#information-we-collect">Types of Information We Collect</a></li>
						<li><a href="#use-of-information">How We Use Your Information</a></li>
						<li><a href="#choices-you-have">What Choices Do You Have?</a></li>
					</ul>
					<div className="page-content-main">
						<div id="introduction">
							<h2>Introduction</h2>
							<p>
								Your privacy is important to the us. Our goal is to provide you with a personalized online experience that provides you with the information, resources, and services that are most relevant and helpful to you. This Privacy Policy has been written to describe the conditions under which this website is being made available to you. The Privacy Policy discusses, among other things, how data obtained during your visit to this website may be collected and used. We strongly recommend that you read the Privacy Policy carefully. By using this website, you agree to be bound by the terms of this Privacy Policy. If you do not accept the terms of the Privacy Policy, you are directed to discontinue accessing or otherwise using the website or any materials obtained from it. If you are dissatisfied with the website, by all means contact us; otherwise, your only recourse is to disconnect from this site and refrain from visiting the site in the future.
								The process of maintaining a website is an evolving one, and BCM may decide at some point in the future, without advance notice, to modify the terms of this Privacy Policy. Your use of the website, or materials obtained from the website, indicates your assent to the Privacy Policy at the time of such use. The effective Privacy Policy will be posted on the website, and you should check upon every visit for any changes.
							</p>
							<h3>SITES COVERED BY THIS PRIVACY POLICY</h3>
							<p>
								This Privacy Policy applies to all the websites, domains, information portals, and registries maintained by BCM.
							</p>
							<h3>CHILDREN’S PRIVACY</h3>
							<p>
								BCM is committed to protecting the privacy needs of children. We encourage parents and guardians to take an active role in their children’s online activities and interests. Our website and services are not directed to children. We do not knowingly collect information from children.
							</p>
							<h3>LINKS TO OTHER WEBSITES</h3>
							<p>
								We may provide links to third-party websites for the convenience of our users. If you access those links, you will leave our website. BCM does not control these third-party websites and cannot represent that their policies and practices will be consistent with this Privacy Policy. We encourage you to review the privacy policy of any website before submitting personal information.
							</p>
						</div>
						<div id="information-we-collect">
							<h2>Types of Information We Collect</h2>
							<h3>NON-PERSONAL INFORMATION</h3>
							<p>
								Non-personal information is data about usage and service operation that is not directly associated with a specific personal identity. BCM may collect and analyze non-personal information to evaluate how visitors use this website.
							</p>
							<h3>AGGREGATE INFORMATION</h3>
							<p>
								We may gather aggregate information, which refers to information your computer automatically provides to us and that cannot be tied back to you as a specific individual. Examples include referral data (the websites you visited just before and just after our site), the pages viewed, time spent at our website, Internet Protocol (IP) addresses, etc. An IP address is a number that is automatically assigned to your computer whenever you access the Internet. For example, when you request a page from one of our sites, our servers log your IP address to create aggregate reports on user demographics and traffic patterns and for purposes of system administration.
							</p>
							<h3>LOG FILES</h3>
							<p>
								Every time you request or download a file from the website, we may store data about these events and your IP address in a log file. We may use this information to analyze trends, administer the website, track users’ movements, and gather broad demographic information for aggregate use or for other business purposes.
							</p>
							<h3>COOKIES</h3>
							<p>
								Our site may use a feature of your browser to set a “cookie” on your computer. Cookies are small packets of information that a website’s computer stores on your computer. Our website can then read the cookies whenever you visit our site. We may use cookies in a number of ways, such as to save your password so you don’t have to re-enter it each time you visit our site, to deliver content specific to your interests and to track the pages you’ve visited. These cookies allow us to use the information we collect to customize your experience so that your visit to our site is as relevant and as valuable to you as possible.
							</p>
							<p>
								Most browser software can be set up to deal with cookies. You may modify your browser preference to provide you with choices relating to cookies. You have the choice to accept all cookies, to be notified when a cookie is set or to reject all cookies. If you choose to reject cookies, certain functions and conveniences of our website may not work properly, and you may be unable to use those of services that require registration in order to participate, or you will have to re-register each time you visit our site. Most browsers offer instructions on how to reset the browser to reject cookies in the “Help” section of the toolbar. We do not link non-personal information from cookies to personally identifiable information without your permission.
							</p>
							<h3>WEB BEACONS</h3>
							<p>
								Our website also may use web beacons to collect non-personal information about your use of our website and the websites of selected sponsors or members, your use of special promotions or newsletters, and other activities. The information collected by web beacons allows us to statistically monitor how many people are using our website and selected sponsors’ sites; how many people open our emails; and for what purposes these actions are being taken. Our web beacons are not used to track your activity outside of our website or those of our sponsors. We do not link non-personal information from web beacons to personally identifiable information without your permission.
							</p>
							<h3>PERSONAL INFORMATION</h3>
							<p>
								Personal information is information that is associated with your name or personal identity. We use personal information to better understand your needs and interests and to provide you with better service. On some of our web pages, you may be able to request information, subscribe to mailing lists, participate in online discussions, collaborate on documents, provide feedback, submit information into registries, register for events, apply for membership, or join technical committees or working groups. The types of personal information you provide to us on these pages may include name, address, phone number, e-mail address, user IDs, passwords, billing information, or credit card information.
							</p>
							<div>
								<h3>FOR ZIKOKO MEMES</h3>
								<ul>
									<li>
										<h2 style={{color: "white", fontSize: "1.2em"}}>Information You Provide To Us</h2>
										<ul>
											<li>
												Account Information: To register as a creator on Zikoko memes, we require the following information; Email address, username and password. Your username can be any string of letters that is unique to you on the platform. Additional information that you may choose but are not required to provide are Bio and profile picture.
											</li>
											<li>
												Your Submission: As a creator, you are allowed to submit memes (these may include images, text or GIFs) uploaded or created by you to be published on the platform. In addition to this, you may choose to add comments to content published by anyone on the platform. These submissions will however be moderated by admin and as such can be taken down or rejected if we believe that they do not meet our standards.
											</li>
										</ul>
									</li>
									<li>
										<h2  style={{color: "white", fontSize: "1.2em"}}>Information We Collect Automatically</h2>
										<ul>
											<li>
												Account Information From Other Services: When registering as a creator, you may choose to sign on with any of the following third-party services; Google, Facebook or Twitter. Depending on which service you authorize with, we will automatically receive some of the following information from that service about your use; Username, Email address, Full name and/or Profile picture.
											</li>
											<li>
												Log And Usage Data: When you access our service(Zikoko memes), we may collect information about your use based on IP address, browser type, operating system, pages visited, links clicked and search terms.
											</li>
										</ul>
									</li>
								</ul>
							</div>							
						</div>
						<div id="use-of-information">
							<h2>How We Use Your Information</h2>
							<p>We use all information we collect from you or from other services to achieve the following;</p>
							<p>1. To provide, maintain, and improve the Services</p>
							<p>2. To monitor and ensure that content published on the domain are appropriate</p>
							<p>3. To send you notices, updates, and other support and administrative messages</p>
							<p>4. Communicate with you about products, services, offers, promotions, and events, and provide other news and information we think will be of interest to you</p> 
							<p>5. Monitor and analyze trends, usage, and activities in connection with our Services</p>
						</div>
						<div id="choices-you-have">
							<h2>What Choices Do you Have?</h2>
							<h3>Opting Out</h3>
							<p>
								From time to time, we may email you electronic newsletters, announcements, surveys or other information. If you prefer not to receive any or all of these communications, you may opt out by following the directions provided within the electronic newsletters and announcements.
							</p>
							<h3>Requesting Data Deletion</h3>
							<p>
								At Zikoko, we respect your right to control your personal data. If you wish to have your personal information deleted from our systems, you can request data deletion by following these steps:
							</p>
							<p>1. Contact our support team: Send an email to <a href = "mailto: hello@bigcabal.com">hello@bigcabal.com</a> with your data deletion request. Please include relevant details like your email address and username to help us identify and process your request efficiently.</p>
							<p>2. Response and verification: We will promptly review and respond to your request to verify your identity and ensure the data in question belongs to you.</p>
							<p>3. Data deletion process: Once your identity and ownership of the data are confirmed, we will proceed with the deletion of your personal information from our systems securely and permanently.</p>
							<p>If you have questions regarding this privacy policy, please contact us.</p>
						</div>
					</div>
				</section>
			</PageLayout>
		</>
	);
}

export default PrivacyPolicy;
