/**
 * Name: All Trending Memes component
 * Description: All Trending memes section
 * Author: omeiza@bigcabal.com
 */
import "./Trending.scss";
import Context from "../../context";
import React, { useContext } from 'react';
import { ListSection, MediaTabs } from "../ListSection";
import GridListing from '../GridListing';
import {Link} from "react-router-dom";

const Section = ({className, title, moreLink, children}) => {
	return (
		<section className={className}>
			<div className="container">
				<div className="grid grid-heading heading-section">
					<h2 className="heading-title">
						{ title instanceof Function ? title() : title }
					</h2>
					{ moreLink ? (
						<Link to={moreLink} className="btn btn-text-icon">
							See more
							<img src="/images/arrow-right.svg" alt="Link Icon" />
						</Link>
					) : null }
				</div>
				{children}
			</div>
		</section>
	);
}

const TrendingAll = ({ count = 50 } = {}) => {
	const { supportedCountries, currentCountry, setCurrentCountry } = useContext(Context);

	const title = () => {
		return (
			<div className="trending-heading-select">
				Trending in
				<select value={currentCountry} onChange={e => setCurrentCountry(e.target.value)}>
					{ Object.keys(supportedCountries).map(country => (
						<option key={country} value={country}>{supportedCountries[country].name}</option>
					))}
				</select>
			</div>
		);
	}

	return (
		<Section className="trending-all list-section" title={title}>
			<GridListing pageClassName="trending-all"
						 endPoint={`/memes/trending/${currentCountry ?? ""}`}
						 columns="4" mobileColumns="2" className="dynamic" masonry="true" autoload={false} count="50" tabs={MediaTabs}/>
		</Section>
	)
}

export default TrendingAll;