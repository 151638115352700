/**
 * Name: Upload Meme component
 * Description: Single Meme
 * Author: omeiza@bigcabal.com
 */
import { useState, useContext, useRef, useEffect } from "react";
import { TagsInput } from "react-tag-input-component";
import axios from "axios";
import {convertToSlug, getFromLocalStorage} from "../../util/helpers";
import MemeUploadedPopup from "./MemeUploadedPopup";
import Context from "../../context";

// Styles
import "./Meme.scss";
import "./../../styles/forms.scss";

// Types
const UploadMeme = ({ imageURL }) => {
	const initialState = { title: "", tags: [], caption: "", source: "", isUploading: false, errorMessage: ""},
		[data, setData] = useState(initialState),
		imageExtension = imageURL ? imageURL.split('.').pop() : null,
		[uploadedMemePopup, setUploadedMemePopup] = useState(false),
		[uploadedMemeURL, setUploadedMemeURL] = useState(null),
		[selectedTags, setSelectedTags] = useState([]),
		[memeUrl, setMemeUrl] = useState(imageURL),
		[isEditMode, setIsEditMode] = useState(false);

	// Filerobot does not support gifs
	// https://github.com/scaleflex/filerobot-image-editor/issues/118

	function handleInputChange(event) {
		setData({
			...data,
			tags: selectedTags,
			[event.target.name]: event.target.value
		});
	}

	function handleMemeUpload(event) {
		event.preventDefault();
		axios.put(`${process.env.REACT_APP_API_BASE_URL}/memes`, {
			title: data.title,
			source: data.source,
			tags: selectedTags,
			caption: data.caption,
			url: memeUrl,
		}, {
			headers: {
				'x-api-key': getFromLocalStorage("key"),
				'Content-Type': 'application/json'
			}
		})
			.then(function (response) {
				if (response.data) {
					const slug = `${convertToSlug(data.title)}-${response.data.id}`;
					setUploadedMemeURL(slug);
					setUploadedMemePopup(true);
					localStorage.removeItem("uploadUrl");
				}
			})
			.catch((err) => {
				setData({
					...data,
					errorMessage: err
				});
			});
	}

	function toggleMemeUploadPopup() {
		setUploadedMemePopup(!uploadedMemePopup);

		if (uploadedMemePopup) {
			document.body.style.overflow = "scroll";
		}
	}

	const ImageEditor = () => {
		const [FilerobotImageEditor, setFilerobotImageEditor] = useState(null);
		const getCurrentImgData = useRef();
		const { isServer } = useContext(Context);

		useEffect(() => {
			if (isServer) return;
			import('react-filerobot-image-editor').then(mod => {
				setFilerobotImageEditor(mod);
			});
		}, []);

		if (isServer) return <div className="serverPlaceHolder"></div>;
	
		const closeImageEditor = () => {
			console.log("CLOSE I SAY!");
		};
	
		if (!memeUrl) {
			return;
		}
	
		const onBeforeSave = imageFileInfo => {
			console.log("BEFORESAVE", imageFileInfo);
			const res = getCurrentImgData.current(imageFileInfo);
			fetch(res.imageData.imageBase64).then(
				x => x.blob().then(blob => {
					const uploadData = {
						"filename": "meme.jpg",
						"mimeType" : blob.type ?? "image/jpeg",
					};
			
					axios.post(`${process.env.REACT_APP_API_BASE_URL}/public/uploadUrl`, uploadData, {
						headers: { 'Content-Type': 'application/json' },
					}).then(res => {
						const data = res.data;
						const formData = new FormData();
						formData.append("Content-Type", uploadData.mimeType);
						Object.entries(data.uploadData.fields).forEach(([k, v]) => formData.append(k, v));
						formData.append('file', blob);
						axios.post(data.uploadData.url, formData, {})
						.then(res => {
							console.log('uploaded', data.getUrl, res);
							setMemeUrl(data.getUrl);
							setIsEditMode(false);
						}).catch(e => {
							console.log('upload err', e);
						});
					}).catch(err => {
						console.log("ERROR", err);
					})
				})
			).catch(e => {
				console.log('error', e);
			});
			return false;
		};

		return (
			FilerobotImageEditor ?
			<FilerobotImageEditor.default
			  source={memeUrl}
			  onClose={closeImageEditor}
			  onSave={() => {}}
			  closeAfterSave={true}
			  getCurrentImgDataFnRef={getCurrentImgData}
			  onBeforeSave={onBeforeSave}
			  Text={{ text: 'Zikoko Memes' }}
			  Rotate={{ angle: 90, componentType: 'slider' }}
			//   tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
			  tabsIds={[FilerobotImageEditor.TABS.ADJUST, FilerobotImageEditor.TABS.ANNOTATE]} 
			  defaultTabId={FilerobotImageEditor.TABS.ANNOTATE} // or 'Annotate'
			  defaultToolId={FilerobotImageEditor.TOOLS.TEXT} // or 'Text'
			/>
			:
			<div className="imageEditorPlaceholder"></div>
		);
	}
	
	return (
		<section className="meme meme-upload">
				{
					isEditMode && imageExtension !== 'gif' ?
					<div className="container" style={{width: '100%', height: '70vh'}}>
						<ImageEditor/>
					</div>
					:
					(
						<div className="container">
						<div className="grid grid-meme-upload">
							<div className="ad">
								<img width={300} height={600} src="/images/example-ad.jpg" alt="Example ad" />
							</div>
							<div className="meme-main">
								<div className="meme-heading">
									<h1>Upload your meme</h1>
								</div>
								<div className="grid grid-meme-form">
									{
										memeUrl ? 
										<div className="meme-uploader-notice">
											<img className="meme-image-main" src={memeUrl} alt="" />
											<div className="meme-uploader-text">
												<button className="meme-edit-button upload-edit btn-text-icon btn-white" type="button" onClick={() => {
													setIsEditMode(true);
												}}>Edit Meme <img src="/images/magicpen.png"/></button>
												<p>Crop, Rotate, Caption, Draw</p>
											</div>
										</div>
										:
										<div className="meme-uploader-wrapper meme-upload-area">
											<button className="meme-uploader-button" type="button" onClick={() => {
												const element = document.querySelector(".meme-create-button");
												if (element) element.click();
											}}>Create a meme</button>
										</div>
									}
									<div className="form form-text">
										<form onSubmit={handleMemeUpload}>
											<div className="form-text-main">
												<label>Title</label>
												<input onChange={handleInputChange} name="title" type="text" 
													placeholder="Add a helpful title" required autoFocus/>
											</div>
											<div className="form-text-main tag-selector">
												<label>Tags (press enter to add new tag)</label>
												<TagsInput
													value={selectedTags}
													onChange={setSelectedTags}
													name="tags"
													placeHolder="Comma, seperated, tags"
												/>
											</div>
											<div className="form-textarea-main">
												<label>Source</label>
												<textarea onChange={handleInputChange} name="source" placeholder="Meme source"/>
											</div>
											<button className="form-submit btn btn-colored btn-full" disabled={data.isUploading}>Upload Meme</button>
										</form>
									</div>
								</div>
							</div>
						</div>
						</div>
					)
				}

			{ uploadedMemePopup && uploadedMemeURL ? <MemeUploadedPopup memeURL={uploadedMemeURL} closeMemeSuccessPopup={toggleMemeUploadPopup.bind(this)} /> : null }
		</section>
	)
}

export default UploadMeme;