import  { useEffect } from "react";
import {useParams} from "react-router-dom";
import {convertToSlug} from "../util/helpers";
import { useNavigate } from "react-router-dom";

// Styles
import "../components/Meme/Meme.scss";
import axios from "axios";

const RedirectMeme = () => {
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/memes/v1/${params.slug}`)
			.then(result => {
				if (result) {
					navigate({
						pathname: `/view/${convertToSlug(result.data.title)}-${result.data.id}`
					});
				} else {
					navigate({
						pathname: `/not-found`
					});
				}
			});
	}, []);
}

export default RedirectMeme;