/**
 * Name: All Top Memes component
 * Description: All Trending memes section
 * Author: omeiza@bigcabal.com
 */

// Styles
import "./Top.scss";

import GridListing from '../GridListing';
import * as React from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ListSection, MediaTabs} from "../ListSection";

const TopAll = () => {
	const [searchParams] = useSearchParams();
	const type = searchParams.get('type');
	const tabs = MediaTabs;
	if (tabs[type]) {
		tabs['_default'] = type;
	}

	return (
		<ListSection title="Top Memes" pageClassName="search-memes" endPoint="/memes" args={{orderBy:'likeCount', order:'desc'}} columns="4" mobileColumns="2" autoload={true} className="dynamic" masonry="true"  tabs={MediaTabs}/>
	)
}

export default TopAll;