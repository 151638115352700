/**
 * Name: Share Meme component
 * Author: omeiza@bigcabal.com
 */
import * as React from "react";

// Style
import "./Meme.scss";
import ReactGA from 'react-ga4';

const createLink = (baseUrl, params = {}) => {
	const url = new URL(baseUrl);
	for (const key in params) {
		url.searchParams.append(key, params[key]);
	}
	return url;
};

const NETWORKS = {
	whatsapp: {
		url: ({ href }) => createLink('https://api.whatsapp.com/send', { text: href }),
		icon: <img src="/images/whatsapp-share.svg" alt="Whatsapp share" />
	},
	facebook: {
		url: ({ href }) => createLink('https://www.facebook.com/sharer/sharer.php', { u: href }),
		icon: <img src="/images/facebook-share.svg" alt="Facebook share" />,
	},
	twitter: {
		url: ({ href, meme }) => createLink('https://twitter.com/share', { url: href, text: meme.title }),
		icon: <img src="/images/twitter-share.svg" alt="Twitter share" />,
	},
	pinterest: {
		url: ({ href, meme }) => createLink('http://pinterest.com/pin/create/link/', { url: href, media: meme.url, description: meme.title }),
		icon: <img src="/images/pinterest-share.svg" alt="Pinterest share" />,
	},
	// tiktok: { // NOT possible
	// 	url: ({ href }) => createLink('https://www.notdoneyet.disney.com/'),
	// 	icon: <img src="/images/tiktok-share.svg" alt="Tiktok share" />,
	// },
}

const ShareLink = ({ network, meme }) => {
	const onClick = () => {
		ReactGA.event({
			category: 'meme',
			action: 'share:' + network,
			value: meme.id,
		});				  
		return true;
	};
	const site = NETWORKS[network];
	// TODO: Canonical URL not window.location.href
	return <a href={site.url({ meme, href: window.location.href })} target="_blank" rel="noreferrer" onClick={onClick}>{ site.icon }</a>;
}

const ShareMeme = ({ meme, closePopup }) => {
	const [memeCopied, setMemeCopied] = React.useState(false);

	React.useEffect(() => {
		document.body.style.overflow = "hidden";
	}, []);

	function copyMemeLink(event) {
		navigator.clipboard.writeText(window.location.href)
			.then(() => {
				setMemeCopied(!memeCopied)
			});
	}

	return (
		<div className="overlay">
			<div className="meme-report">
				<div className="meme-report-heading">
					<h2>Share Meme</h2>
					<img onClick={closePopup} src="/images/close-icon.svg" className="menu-close" alt="Close Menu" />
				</div>
				<div className="meme-report-details meme-report-submitted">
					{ memeCopied ?
						<div onClick={copyMemeLink} className="btn btn-full btn-white">Link Copied!</div>
						:
						<div onClick={copyMemeLink} className="btn btn-full btn-colored">Copy Link</div>
					}
					<ul className="meme-share-social">
						<li><ShareLink meme={meme} network="facebook"/></li>
						<li><ShareLink meme={meme} network="whatsapp"/></li>
						<li><ShareLink meme={meme} network="pinterest"/></li>
						{/* <li><ShareLink meme={meme} network="tiktok"/></li> */}
						<li><ShareLink meme={meme} network="twitter"/></li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default ShareMeme;