import {Link} from "react-router-dom";
import {useApiRequest} from "../../lib/api";

// Styles
import "./Banner.scss";

const getCompBannerImages = (link) => {
	const compBannerImageList = [
		"/images/competition-img-1.png",
		"/images/competition-img-2.png",
		"/images/competition-img-3.png",
		"/images/competition-img-4.png",
		"/images/competition-img-5.png"
	];

	return (
		<ul className="grid grid-competition-images images">
			{compBannerImageList.map(compBannerImage => (
				<li><Link target="_blank" to={link}><img src={compBannerImage} /></Link></li>
			))}
		</ul>
	);
};

const Competition = () => {
	const [competitionSettings, isFound] = useApiRequest(`${process.env.REACT_APP_API_BASE_URL}/competition`);
	if (isFound && false) {
		if (competitionSettings.type === 'vote_a_meme') {
			return (
				<section className="banner">
					<div className="container">
						<div className="banner-competition">
							<div className="grid grid-competition banner-competition-info">
								<div className="description">
									<h2>{competitionSettings.title}</h2>
									<p>{competitionSettings.tagline}</p>
								</div>
								{getCompBannerImages(`search?type=All&tag=${competitionSettings.tag}`)}
							</div>
							<div className="links">
								<Link className="btn btn-colored" target="_blank" to={`search?type=All&tag=${competitionSettings.tag}`}>View all nominations</Link>
								<a className="ord-link" rel="noreferrer" target="_blank" href={competitionSettings.rules}>How to participate</a>
							</div>
						</div>
					</div>
				</section>
			)
		}

		return (
			<section className="banner">
				<div className="container">
					<div className="banner-competition banner-competition-remix">
						<div className="grid grid-competition-remix banner-competition-info">
							<div className="description">
								<h2>{competitionSettings.title}</h2>
								<p>{competitionSettings.tagline}</p>
								<div className="links">
									<Link className="btn btn-colored" target="_blank" to={`search?type=All&tag=${competitionSettings.tag}`}>View all submissions</Link>
									<a className="ord-link" rel="noreferrer" target="_blank" href={competitionSettings.rules}>How to participate</a>
								</div>
							</div>
							<div>
								{/* <Link target="_blank" to={`search?type=All&tag=${competitionSettings.tag}`}><img src="/images/macaroni-shaggi.jpg" /></Link> */}
								<Link target="_blank" to={`/view/battleofthememes-5121`}><img src="/images/macaroni-shaggi.jpg" /></Link>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}

	return null;
}

export default Competition;