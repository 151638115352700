/**
 * Name: Report Memee component
 * Author: omeiza@bigcabal.com
 */
import * as React from "react";
import axios from "axios";
import {getFromLocalStorage} from "../../util/helpers";

// Style
import "./Meme.scss";

const ReportMeme = (props) => {
	const [memeReported, setMemeReported] = React.useState(false),
		[memeReportTriggered, setMemeReportTriggered] = React.useState(false),
		[customReport, setCustomReport] = React.useState(false),
		[reportText, setReportText] = React.useState(""),
		[reportDesc, setReportDesc] = React.useState(""),
		initialState = { reportMessage: "", isSubmitting: false, errorMessage: ""},
		[data, setData] = React.useState(initialState);

	React.useEffect(() => {
		document.body.style.overflow = "hidden";
	}, []);

	const reportMeme = (event) => {
		const {target} = event;
		if (target) {
			setReportText(target.getAttribute('data-text'));
			setReportDesc(target.getAttribute('data-description'));

			setMemeReportTriggered(!memeReportTriggered);

			// if (props.id && reportReason) {
			// 	axios.post(`${process.env.REACT_APP_API_BASE_URL}/memes/${props.id}/report`, {
			// 		reason: reportReason
			// 	}, {
			// 		headers: {
			// 			'x-api-key': getFromLocalStorage("key")
			// 		}
			// 	})
			// 		.then(function (response) {
			// 			setMemeReported(!memeReported);
			// 		});
			// }
 		}
	}

	const sendReport = () => {
		if (props.id && reportText) {
			axios.post(`${process.env.REACT_APP_API_BASE_URL}/memes/${props.id}/report`, {
				reason: reportText
			}, {
				headers: {
					'x-api-key': getFromLocalStorage("key")
				}
			})
				.then(function (response) {
					if (response) {
						setMemeReportTriggered(!memeReportTriggered);
						setMemeReported(!memeReported);
						setReportText("");
						setReportDesc("");
					}
				});
		}
	}

	const handleInputChange = (event)=> {
		setData({
			...data,
			[event.target.name]: event.target.value
		});
	};

	function handleCustomReport(event) {
		event.preventDefault();

		setData({
			...data,
			isSubmitting: true,
			errorMessage: ""
		});

		if (data.reportMessage) {
			axios.post(`${process.env.REACT_APP_API_BASE_URL}/memes/${props.id}/report`, {
				reason: data.reportMessage
			}, {
				headers: {
					'x-api-key': getFromLocalStorage("key")
				}
			})
				.then(function (response) {
					setMemeReported(!memeReported);
				});
		}
	}

	function switchToCustomReport() {
		setCustomReport(!customReport);
	}

	function switchToAllReport() {
		setCustomReport(!customReport);
	}

	return (
		<div className="overlay">
			{ memeReportTriggered ?
				<div className="meme-report">
					<div className="meme-report-heading">
						<h2>More Information</h2>
						<img onClick={props.closePopup} src="/images/close-icon.svg" className="menu-close" alt="Close Menu" />
					</div>
					<div className="meme-report-details meme-report-submitted">
						<div className="more-report-p">
							<p>{reportDesc}</p>
						</div>
						<div onClick={sendReport} className="btn btn-colored btn-full">Submit Report</div>
					</div>
				</div>
				:
				<>
					{ memeReported ?
						<div className="meme-report">
							<div className="meme-report-heading">
								<h2>Report Submitted</h2>
								<img onClick={props.closePopup} src="/images/close-icon.svg" className="menu-close" alt="Close Menu" />
							</div>
							<div className="meme-report-details meme-report-submitted">
								<div onClick={props.closePopup} className="btn btn-colored btn-full">Close</div>
							</div>
						</div>
						:
						customReport ?
							<div className="meme-report">
								<div className="meme-report-heading">
									<h2><img onClick={switchToAllReport} className="back-to-previous" src="/images/arrow-back.svg" alt="Go back" /> Report</h2>
									<img onClick={props.closePopup} src="/images/close-icon.svg" className="menu-close" alt="Close Menu" />
								</div>
								<div className="meme-report-details">
									<form onSubmit={handleCustomReport}>
										<div className="form-password-main">
											<textarea name="reportMessage" onChange={handleInputChange} placeholder="Please type out your reasons here" required />
										</div>
										<button className="form-submit btn btn-colored btn-full" disabled={data.isSubmitting}>
											{data.isSubmitting ? (
												"Submitting..."
											) : (
												"Submit Report"
											)}
										</button>
									</form>
								</div>
							</div>
							:
							<div className="meme-report">
								<div className="meme-report-heading">
									<h2>Report</h2>
									<img onClick={props.closePopup} src="/images/close-icon.svg" className="menu-close" alt="Close Menu" />
								</div>
								<div className="meme-report-details">
									<h3>Why are you reporting this?</h3>
									<ul>
										<li onClick={reportMeme} data-text="Adult/pornographic content" data-description="The post shows or represents human genitals, nudity, or any explicit sexual act.">Adult/pornographic content <img src="/images/arrow-right-thin.svg" alt="Continue Arrow" /></li>
										<li onClick={reportMeme} data-text="Spam posts" data-description="The post is irrelevant or shows unsolicited content for the purposes of advertising, phishing, spreading malware, etc.">Spam posts <img src="/images/arrow-right-thin.svg" alt="Continue Arrow" /></li>
										<li onClick={reportMeme} data-text="Violence, mutilation, and self-harm" data-description="The post depicts or encourages violence, mutilation or torture of someone.">Violence, mutilation, and self-harm <img src="/images/arrow-right-thin.svg" alt="Continue Arrow" /></li>
										<li onClick={reportMeme} data-text="Hate Speech and Harassment" data-description="The post bullies, harasses, abuses, dehumanizes, or incites hatred against any individuals or groups based on race, ethnicity, religion, gender identity, sexual orientation, disability, or disease.">Hate Speech and Harassment <img src="/images/arrow-right-thin.svg" alt="Continue Arrow" /></li>
										<li onClick={reportMeme} data-text="Private personal information" data-description="The post reveals personal/private information about any person or organization.">Private personal information <img src="/images/arrow-right-thin.svg" alt="Continue Arrow" /></li>
										<li onClick={reportMeme} data-text="Copyrighted content" data-description="This post shows an image that is trademarked or copyrighted by someone else or me.">Copyrighted content <img src="/images/arrow-right-thin.svg" alt="Continue Arrow" /></li>
									</ul>
									<ul>
										<li onClick={switchToCustomReport}>Other Reason <img src="/images/arrow-right-thin.svg" alt="Continue Arrow" /></li>
									</ul>
								</div>
							</div>
					}
				</>
			}
		</div>
	)
}

export default ReportMeme;