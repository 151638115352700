import * as React from "react";
import PageLayout from "../../components/Layout/PageLayout";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const CommunityGuideline = () => {
	const location = useLocation();

	return (
		<>
			<Helmet>
				<title>Z! Community Standards | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="Z! Community Standards | Zikoko! Memes"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<PageLayout title="Z! Community Standards" updated="">
				<section className="grid grid-page-content page-content">
					<ul className="page-anchors">
						<li><a href="#introduction">Introduction</a></li>
						<li><a href="#user-submission">User Submissions</a></li>
						<li><a href="#discussion-forum">User Discussion Lists and Forums</a></li>
						<li><a href="#personal-information">Use of Personally Identifiable Information</a></li>
						<li><a href="#indemnification">Indemnification</a></li>
						<li><a href="#termination">Termination</a></li>
					</ul>
					<div className="page-content-main">
						<div id="introduction">
							<h2>Introduction</h2>
							<p>
								Zikoko Memes is a platform for finding and creating culture relevant memes that people can tap into to participate in trending conversations. As a creator on this platform, we want you to express your creativity by creating, sharing and publishing relatable and appropriate content that can be used by other creators and viewers on the platform. We have created the following guidelines to help you ensure that your content is safe, appropriate and thereby not rejected by our moderators.
							</p>
						</div>
						<div id="information-we-collect">
							<h2>Guidelines</h2>
							<ol>
								<li>
									<h3>Adult/pornographic content</h3>
									<p>Do not post content (memes, comments or bio) that show or represent human genitals, nudity or any explicit sexual act.</p>
								</li>
								<li>
									<h3>Violence, mutilation and self-harm</h3>
									<p>Do not post content that depict or encourage violence against someone or gory images that depict mutilation or torture of any being.</p>
								</li>
								<li>
									<h3>Spam posts</h3>
									<p>Do not post irrelevant or unsolicited content (memes or comments) for the purposes of advertising, phishing, spreading malware, etc. Spamming users will lead to deactivation of your account.</p>
								</li>
								<li>
									<h3>Hate Speech and Harassment</h3>
									<p>Do not post content that bullies, harasses, abuses, dehumanizes, or incite hatred against any individuals or groups based on race, ethnicity, religion, gender, gender identity, sexual orientation, disability or disease.</p>
								</li>
							</ol>
						</div>
					</div>
				</section>
			</PageLayout>
		</>
	);
}

export default CommunityGuideline;
