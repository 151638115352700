import {useState} from "react";
import {useNavigate} from "react-router-dom";

// Styles
import "../../styles/forms.scss";
import axios from "axios";

const ForgotPasswordForm = () => {
	const initialState = { email: "", isSubmitting: false, errorMessage: "" },
		[data, setData] = useState(initialState),
		[formStep, setFormStep] = useState(1),
		navigate = useNavigate();

	const handleInputChange = (event)=> {
		const target = event.target;
		setData({
			...data,
			email: target.value
		});
	};

	const handleForgetPassword = (event) => {
		event.preventDefault();
		setData({
			...data,
			isSubmitting: true,
			errorMessage: ""
		});

		axios.post(`${process.env.REACT_APP_API_BASE_URL}/resetPassword/generate`, {
			email: data.email,
		})
			.then(function (response) {
				console.log(response);
				// Use reset token with email
				// @TODO
				setFormStep(2);
			})
			.catch((err) => {
				if (err.response) {
					setData({
						...data,
						isSubmitting: false,
						errorMessage: "User does not exist"
					});
				}
			});
	};

	if (formStep === 2) {
		return (
			<div className="form form-email form-forgot-password">
				<img className="back-to-previous" onClick={() => navigate(-1)} src="/images/arrow-back.svg" alt="Go back"/>
				<h2>Reset Password Email Sent!</h2>
				<p>Check your  email provider for our message with a link to reset your password. Ensure you used the right email address.</p>
				<p>Didn’t get a link? <a href="/" onClick={e => {
					e.preventDefault(); setFormStep(1);
					setData({ ...data, isSubmitting: false });
				}}>Resend</a></p>
			</div>
		)
	}

	return (
		<div className="form form-email form-forgot-password">
			<img className="back-to-previous" onClick={() => navigate(-1)} src="/images/arrow-back.svg" alt="Go back"/>
			<h2>Forgot Password?</h2>
			<p>Enter the email you used to create your account. If it matches that in our system, we’ll send a resend link to your email.</p>
			{
				!data.errorMessage || data.errorMessage === "" ?
					null
					:
					<div className="form-error-message">
						{data.errorMessage}
					</div>
			}
			<form onSubmit={handleForgetPassword}>
				<div className="form-email-main">
					<label>Email</label>
					<input onChange={handleInputChange} name="email" type="email" placeholder="Enter your email" required/>
				</div>
				<button className="form-submit btn btn-colored btn-full" disabled={data.isSubmitting}>
					{data.isSubmitting ? (
						"Sending link..."
					) : (
						"Send link to reset password"
					)}
				</button>
			</form>
		</div>
	);
}

export default ForgotPasswordForm;