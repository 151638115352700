import React from "react";
import Layout from "../components/Layout/Layout";
import {useNavigate, useParams} from "react-router-dom";
import {useApiRequest} from "../lib/api";
import RemixMeme from "../components/Meme/RemixMeme";

const RemixMemeRoute = () => {
	const params = useParams();
	const navigate = useNavigate();
	const slug = params.slug;
	// const auth = useAuth();

	let slugArray, id;

	if (slug) {
		slugArray = slug.split("-");
		id = parseInt(slugArray[slugArray.length - 1]);
	} else {
		navigate('/not-found');
	}

	const [meme, isFound] = useApiRequest(`${process.env.REACT_APP_API_BASE_URL}/memes/${id}`);
	return (
		<Layout>
			<main>
				<RemixMeme meme={meme} />
			</main>
		</Layout>
	);
}

export default RemixMemeRoute;