import axios from "axios";
import {getFromLocalStorage} from "../../util/helpers";
import {useAuth} from "../Auth/AuthProvider";
import {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";

// Styles
import "./User.scss";

const ProfileEdit = () => {
	const navigate = useNavigate();
	const uploadClick = useRef(null);
	const auth = useAuth();
	const [data, setData] = useState({ image: auth.user?.image, username: auth.user?.username, bio: auth.user?.bio, isSubmitting: false, errorMessage: "", successMessage: ""});
	const [thumb, setThumb] = useState(null);
	const [signedData, setSignedData] = useState(null);
	const [uploadFile, setUploadFile] = useState(null);

	useEffect(() => {
		if (uploadFile) {
			const reader = new FileReader();

			reader.onloadend = () => {
				setThumb(reader.result);

				// Now let's upload
				getSignedURL();
			};

			reader.readAsDataURL(uploadFile);
		}
	}, [uploadFile]);

	//@TODO Make dry
	function getSignedURL() {
		if (uploadFile) {
			const data = {
				"filename": uploadFile.name,
				"mimeType" : uploadFile.type
			}

			axios.post(`${process.env.REACT_APP_API_BASE_URL}/public/uploadUrl`, data, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then ((res) => {
					setSignedData(res.data);
				})
				.catch ((error) => {
					console.error(error);
				})
		}
	}

	const handleUpload = async () => {
		const formData = new FormData(),
			origin = new URL(document.location.href);
		origin.pathname = '';
		origin.search = '';

		if (uploadFile && signedData) {
			formData.append("Content-Type", uploadFile.type);
			Object.entries(signedData.uploadData.fields).forEach(([k, v]) => {
				formData.append(k, v);
			});

			formData.append("file", uploadFile);
			return axios.post(signedData.uploadData.url, formData, {
				headers: {
					"Access-Control-Allow-Origin" : "*",
					// "Origin": origin,
				}
			});
		}
	}

	const handleImageSelection = (event) => {
		const files = event.currentTarget.files;
		if (files) {
			setUploadFile(files[0]);
		}
	}

	const handleInputChange = (event)=> {
		setData({
			...data,
			[event.target.name]: event.target.value
		});
	};

	const handleProfileUpdate = async (event) => {
		event.preventDefault();

		setData({
			...data,
			isSubmitting: true,
			errorMessage: ""
		});

		// Wait for final part of image upload
		await handleUpload();

		axios.post(`${process.env.REACT_APP_API_BASE_URL}/users`, {
			username: (!data.username || data.username === "") ? auth.user?.username : data.username,
			bio: data.bio,
			image: signedData?.getUrl
		}, {
			headers: {
				'x-api-key': getFromLocalStorage("key")
			}
		})
			.then((response) => {
				const key = getFromLocalStorage("key");
				auth.getUser(key).then(() => {
					navigate({
						pathname: '/profile/' + encodeURIComponent(data.username ?? auth.user?.username),
					});	
				});	
			})
			.catch(err => {
				console.error(err);
				setData({
					...data,
					isSubmitting: false,
					errorMessage: "Error updating user"
				});
			})
	};

	if (!auth.user) {
		return null;
	}

	return (
		<section className="profile">
			<div className="container">
				<div className="grid heading-section">
					<h2 className="heading-title">
						Edit Profile
					</h2>
				</div>
				<div className="grid profile-wrapper">
					<div className="profile-info">
						<div className="profile-info-main">
							{
								auth.user.image ?
									<div className="profile-info-img" style={{backgroundImage: `url(${auth.user.image})`}} />
									:
									<div className="profile-info-img">
										<img src="/images/profile-circle.svg" alt="Profile" />
									</div>
							}
							<p className="profile-info-username">{`@${auth.user.username}`}</p>
							{
								auth.user.bio ?
									<p className="profile-info-description">{auth.user.bio}</p>
									:
									null
							}
						</div>
					</div>
					<div>
						{
							!data.errorMessage || data.errorMessage === "" ?
								null
								:
								<div className="form-error-message">
									{ data.errorMessage }
								</div>
						}
						{
							!data.successMessage || data.successMessage === "" ?
								null
								:
								<div className="form-success-message">
									{ data.successMessage }
								</div>
						}
						<div className="profile-content profile-edit">
							<div className="profile-content-main">
								<div className="form form-email">
									<div>
										<form onSubmit={handleProfileUpdate}>
											<div className="form-upload-main" style={{backgroundImage: `url("${thumb ? thumb : auth.user?.image}")`, backgroundSize: "cover", backgroundPosition: 'center'}}>
												<img src="/images/camera.svg" alt="Upload button" onClick={() => { const a = uploadClick.current; if (a) a.click(); }} />
												<input id="file" name="file" type="file" ref={uploadClick} onChange={handleImageSelection} />
											</div>
											<div className="form-email-main">
												<label>Username</label>
												<input onChange={handleInputChange} type="text" value={data.username ?? auth.user?.username} name="username" placeholder={auth.user?.username} />
											</div>
											<div className="form-textarea-main">
												<label>Add a Bio</label>
												<textarea onChange={handleInputChange} name="bio" value={data.bio ?? auth.user?.bio} placeholder={auth.user?.bio}/>
											</div>
											<button className="form-submit btn btn-colored btn-full">
												{data.isSubmitting ? (
													"Updating..."
												) : (
													"Update Profile"
												)}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ProfileEdit;