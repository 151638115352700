import {GridListing} from "../GridListing";
import {ListSection} from "../ListSection";
import {useEffect, useState} from 'react';
import sample from './sample.json';
import axios from 'axios';

// Style
import "./Leaderboard.scss";
import {Link} from "react-router-dom";

const ordinalSuffix = i => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
};

const lbItemHandler = ({ item, position = 1 }) => {
	// { "id": 8, "username": "nottakingyourname", "image": null, "bio": "gdjdj", "points": 0, "dailyPoints": 0, "weeklyPoints": 0 }
	const badge = 
		position === 1 ? '/images/gold.svg' :
		position === 2 ? '/images/silver.svg' :
		position === 3 ? '/images/bronze.svg' : null;

	return (
		<>
			<img width="50" height="50" className="rounded" src={item.image ?? "/images/profile-circle.svg"} alt={item?.username} />
			<span className="user">
			<Link to={`/profile/${item.username}`}>@{item.username}</Link>
			</span>
			<span className="points">{item.pts}</span>
			<p className="rank">
				{ badge ? 
				<img className="badge" src=	{ badge } alt="" /> : null }
				<span className="position">{ ordinalSuffix(position) }</span></p>
		</>
	);
}

const Leaderboard = () => {
	const [entries, setEntries] = useState([]);

	useEffect(() => {
		let url = process.env.REACT_APP_API_BASE_URL + '/leaderboard';
		axios.get(url).then(res => {
			if (res.status === 200) {
				setEntries(res.data);
			}
		});
	}, []);


	  if (true) {
	  return (
		<>
		<ListSection 
			pageClassName="leaderboard" 
			columns="1" count={50} placeholderCount={10} ItemHandler={lbItemHandler}

			tabs={{
				Daily: {
					items: entries?.daily?.map(x => ({ ...x, pts: x.dailyPoints })),
				},
				Weekly: {
					items: entries?.weekly?.map(x => ({ ...x, pts: x.weeklyPoints })),
				},
				AllTime: {
					items: entries?.allTime?.map(x => ({ ...x, pts: x.points })),
				},
				_default: 'Daily',				
			}}
		/>
		</>
	  );
	  }

	return (
		<section className="leaderboard">
			<div className="container">
				<div className="leaderboard-content">
					<ul className="leaderboard-tabs">
						<li><Link to={"/"}>Weekly</Link></li>
						<li><Link to={"/"}>Monthly</Link></li>
						<li><Link className="active" to={"/"}>All Time</Link></li>
					</ul>
					<GridListing items={sample.allTime} columns="1" count={50} placeholderCount={10} ItemHandler={lbItemHandler}/>
				</div>
			</div>
		</section>
	)
}

export default Leaderboard;