import * as React from "react";
import Layout from "../components/Layout/Layout";
import UploadMeme from "../components/Meme/UploadMeme";
import {getFromLocalStorage} from "../util/helpers";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const UploadMemeRoute = () => {
	const image = getFromLocalStorage("uploadUrl");
	const location = useLocation();

	return (
		<>
			<Helmet>
				<title>Upload meme | Zikoko! Memes</title>
				<meta name="description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans." />
				<link rel="canonical" href={process.env.REACT_APP_BASE_URL + location.pathname} />
				<meta property="og:title" content="Upload meme | Zikoko! Memes"/>
				<meta property="og:url" content={process.env.REACT_APP_BASE_URL + location.pathname}/>
				<meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/images/zikoko-memes-img.jpg`} />
				<meta property="og:description" content="Zikoko Memes is your go-to search engine for relatable and culturally relevant memes, created by and for black people and Africans."/>
			</Helmet>
			<Layout>
				<main>
					<UploadMeme imageURL={image} />
				</main>
			</Layout>
		</>
	);
}

export default UploadMemeRoute;
