/**
 * Name: Search component
 * Description: Site wide search
 * Author: omeiza@bigcabal.com
 */
import {useSearchParams} from "react-router-dom";
import {useState} from 'react';

// Styles
import "./Search.scss";

const Search = () => {
	const [searchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(searchParams.get('q') ?? "");
	const [searchType, setSearchType] = useState(searchParams.get('type') || 'All');
	return (
		<section className="search">
			<div className="container">
				<form method="get" action="/search" className="search-form grid grid-search">
					<div className="search-meme-type">
						<select name="type" value={searchType} onChange={e => setSearchType(e.target.value)}>
							<option key="All">All</option>
							<option key="Images">Images</option>
							<option key="GIFs">GIFs</option>
						</select>
					</div>
					<div className="search-input">
						<img src="/images/search-icon.svg" alt="Search memes"/>
						<input type="text" name="q" placeholder="Search for memes" value={searchQuery} onChange={(evt) => setSearchQuery((evt.target.value).trim())}/>
					</div>
				</form>
			</div>
		</section>
	)
}

export default Search;