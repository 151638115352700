import * as React from "react";
import Layout from "../components/Layout/Layout";
import EditMeme from "../components/Meme/EditMeme";
import {useParams} from "react-router-dom";

const EditMemeRoute = () => {
	const params = useParams();
	const id = params.id;

	return (
		<Layout>
			<main>
				<EditMeme id={id} />
			</main>
		</Layout>
	);
}

export default EditMemeRoute;
